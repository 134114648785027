import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  close as closeConfirmModal
} from '../confirmModal/actions';

import {
  apiGetCultureDocs,
  apiDeleteDoc,
} from './api';

import {
  actionTypes,

  getCultureDocsUnpublishedRequest,
  getCultureDocsUnpublishedSuccess,
  getCultureDocsUnpublishedError,

  deleteDocRequest,
  deleteDocSuccess,
} from './actions';

function* getCultureDocsUnpublishedSaga(data) {
  try {
    yield put(getCultureDocsUnpublishedRequest());

    const { data: { data: cultureDocs } } = yield call(() => apiGetCultureDocs({
      isPublished: false,
    }));

    yield put(getCultureDocsUnpublishedSuccess({
      cultureDocs,
    }));
  } catch (error) {
    console.log('GET CULTURE DOCS UNPUBLISHED ERROR', error);
    yield put(getCultureDocsUnpublishedError(error));
  }
}

function* deleteDocSaga({ docId }) {
  try {
    yield put(deleteDocRequest());

    yield call(() => apiDeleteDoc(docId));

    yield put(deleteDocSuccess(docId));

    yield put(closeConfirmModal());

    store.addNotification({
      type: 'success',
      message: 'Документ удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('deleteDocSaga ERROR', error);

    yield put(deleteDocSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Документ не удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_CULTURE_DOCS_UNPUBLISHED, getCultureDocsUnpublishedSaga);
  yield takeEvery(actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED, deleteDocSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
