import { actionTypes } from './actions';

const initialState = {
  usersUnits: [],
  getUsersUnitsRequest: false,
  getUsersUnitsSuccess: false,
  getUsersUnitsError: false,

  usersUnitsUsed: [],
  getUsersUnitsUsedRequest: false,
  getUsersUnitsUsedSuccess: false,
  getUsersUnitsUsedError: false,
};

const handleGetUsersUnitsRequest = (state) => ({
  ...state,
  getUsersUnitsRequest: true,
  getUsersUnitsSuccess: false,
  getUsersUnitsError: false,
});

const handleGetUsersUnitsSuccess = (state, action) => ({
  ...state,
  getUsersUnitsRequest: false,
  getUsersUnitsSuccess: true,
  getUsersUnitsError: false,
  usersUnits: action.data,
});

const handleGetUsersUnitsError = (state) => ({
  ...state,
  getUsersUnitsRequest: false,
  getUsersUnitsSuccess: false,
  getUsersUnitsError: true,
});

const handleGetUsersUnitsUsedRequest = (state) => ({
  ...state,
  getUsersUnitsUsedRequest: true,
  getUsersUnitsUsedSuccess: false,
  getUsersUnitsUsedError: false,
});

const handleGetUsersUnitsUsedSuccess = (state, action) => ({
  ...state,
  getUsersUnitsUsedRequest: false,
  getUsersUnitsUsedSuccess: true,
  getUsersUnitsUsedError: false,
  usersUnitsUsed: action.data,
});

const handleGetUsersUnitsUsedError = (state) => ({
  ...state,
  getUsersUnitsUsedRequest: false,
  getUsersUnitsUsedSuccess: false,
  getUsersUnitsUsedError: true,
});


const handleSetUsersUnitUsed = (state, action) => {
  const usersUnitsUsed = [...state.usersUnitsUsed];

  // Add user unit to usersUnitsUsed and set it to isUsed: true/
  usersUnitsUsed.unshift({
    ...action.data,
    isUsed: true,
  });

  return {
    ...state,
    usersUnitsUsed,
  };
};

const handleUnsetUsersUnitUsed = (state, action) => {
  const unitId = action.data.unitId;

  const foundIndex = state.usersUnitsUsed.findIndex((usersUnit) => usersUnit.unitId === unitId);

  const usersUnitsUsed = [...state.usersUnitsUsed];

  usersUnitsUsed.splice(foundIndex, 1);

  return {
    ...state,
    usersUnitsUsed,
  };
};


const handleResetState = (state) => ({
  ...state,
  usersUnits: initialState.usersUnits,
  usersUnitsRequest: initialState.usersUnitsRequest,
  usersUnitsSuccess: initialState.usersUnitsSuccess,
  usersUnitsError: initialState.usersUnitsError,
});

const handleResetStateUsed = (state) => ({
  ...state,
  usersUnitsUsed: initialState.usersUnitsUsed,
  usersUnitsUsedRequest: initialState.usersUnitsUsedRequest,
  usersUnitsUsedSuccess: initialState.usersUnitsUsedSuccess,
  usersUnitsUsedError: initialState.usersUnitsUsedError,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_USERS_UNITS]: handleResetState,
    [actionTypes.RESET_STATE_USERS_UNITS_USED]: handleResetStateUsed,

    [actionTypes.SET_USERS_UNIT_USED]: handleSetUsersUnitUsed,
    [actionTypes.UNSET_USERS_UNIT_USED]: handleUnsetUsersUnitUsed,

    [actionTypes.GET_USERS_UNITS_REQUEST]: handleGetUsersUnitsRequest,
    [actionTypes.GET_USERS_UNITS_SUCCESS]: handleGetUsersUnitsSuccess,
    [actionTypes.GET_USERS_UNITS_ERROR]: handleGetUsersUnitsError,

    [actionTypes.GET_USERS_UNITS_USED_REQUEST]: handleGetUsersUnitsUsedRequest,
    [actionTypes.GET_USERS_UNITS_USED_SUCCESS]: handleGetUsersUnitsUsedSuccess,
    [actionTypes.GET_USERS_UNITS_USED_ERROR]: handleGetUsersUnitsUsedError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
