export const actionTypes = {
  GET_USERS_UNITS_TYPES: 'GET_USERS_UNITS_TYPES',
  GET_USERS_UNITS_TYPES_REQUEST: 'GET_USERS_UNITS_TYPES_REQUEST',
  GET_USERS_UNITS_TYPES_SUCCESS: 'GET_USERS_UNITS_TYPES_SUCCESS',
  GET_USERS_UNITS_TYPES_ERROR: 'GET_USERS_UNITS_TYPES_ERROR',

  RESET_STATE: 'RESET_STATE',
};

export const resetStateUsersUnitsTypes = () => ({ type: actionTypes.RESET_STATE });

export const getUsersUnitsTypes = () => ({ type: actionTypes.GET_USERS_UNITS_TYPES });

export const getUsersUnitsTypesRequest = () => ({ type: actionTypes.GET_USERS_UNITS_TYPES_REQUEST });

export const getUsersUnitsTypesSuccess = (data) => ({ type: actionTypes.GET_USERS_UNITS_TYPES_SUCCESS, data });

export const getUsersUnitsTypesError = (error) => ({ type: actionTypes.GET_USERS_UNITS_TYPES_ERROR, error });
