import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { apiGetUsers } from './api';

import {
  actionTypes,
  getUsersRequest,
  getUsersSuccess,
} from './actions';

function* getUsersSaga() {
  try {
    yield put(getUsersRequest());

    const {
      data: { data: users },
    } = yield call(() => apiGetUsers());

    yield put(
      getUsersSuccess({
        users,
      }),
    );
  } catch (error) {
    console.log('GET USERS AI ERROR', error);
    }  
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_USERS_AI, getUsersSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
