import { actionTypes } from './actions';

const initialState = {
  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: false,
  cultureDocsListUnpublished: [],

  deleteDocRequest: false,
};

const handleResetState = () => ({
  ...initialState,
});

const handleGetCultureDocsUnpublishedRequest = (state) => ({
  ...state,
  getCultureDocsUnpublishedRequest: true,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: false,
});

const handleGetCultureDocsUnpublishedSuccess = (state, action) => ({
  ...state,
  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: true,
  getCultureDocsUnpublishedError: false,
  cultureDocsListUnpublished: action.cultureDocs,
});

const handleGetCultureDocsUnpublishedError = (state) => ({
  ...state,
  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: true,
});

const handleDeleteDocRequest = (state) => ({
  ...state,
  deleteDocRequest: true,
});

const handleDeleteDocSuccess = (state, action) => {
  const _cultureDocsListUnpublished = [...state.cultureDocsListUnpublished];

  const docIndex = _cultureDocsListUnpublished.findIndex((doc) => {
    return doc.id === action.docId;
  });

  _cultureDocsListUnpublished.splice(docIndex, 1);

  return {
    ...state,
    cultureDocsListUnpublished: _cultureDocsListUnpublished,
    deleteDocRequest: false,
  }
};

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_CULTURE_DOCS_UNPUBLISHED]: handleResetState,

    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_REQUEST]: handleGetCultureDocsUnpublishedRequest,
    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_SUCCESS]: handleGetCultureDocsUnpublishedSuccess,
    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_ERROR]: handleGetCultureDocsUnpublishedError,

    [actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_REQUEST]: handleDeleteDocRequest,
    [actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_SUCCESS]: handleDeleteDocSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
