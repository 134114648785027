import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetNews = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.news.list,
  params,
});

export const apiDeleteNewsArticle = (newsId) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.news.delete,
  params: {
    newsId,
  },
});
