import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  close as closeConfirmModal
} from '../confirmModal/actions';

import {
  apiGetNews,
  apiUpdateNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsRequest,
  getNewsSuccess,
  getNewsError,

  getMoreNewsRequest,
  getMoreNewsSuccess,
  getMoreNewsError,

  unpublishNewsArticleRequest,
  unpublishNewsArticleSuccess,
} from './actions';


function* getNewsSaga(data) {
  try {
    yield put(getNewsRequest());
    const { offset, limit } = yield select(({ newsService }) => newsService);

    const { data: { data: news } } = yield call(() => apiGetNews({
      offset,
      limit: data.params.limit || limit,
    }));

    yield put(getNewsSuccess({
      news,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET NEWS ERROR', error);
    yield put(getNewsError(error));
  }
}

function* getMoreNewsSaga({ search }) {
  try {
    yield put(getMoreNewsRequest());
    const { offset, limit } = yield select(({ newsService }) => newsService);
    const params = { offset, limit };

    if (search && search.length) {
      params.search = search;
    }

    const { data: { data: news } } = yield call(() => apiGetNews(params));

    yield put(getMoreNewsSuccess({
      news,
      offset: offset + limit,
      isNoMoreNews: !news.length,
    }));
  } catch (error) {
    console.log('MORE NEWS ERROR', error);
    yield put(getMoreNewsError(error));
  }
}

function* unpublishNewsArticleSaga({ newsId }) {
  try {
    yield put(unpublishNewsArticleRequest());

    yield call(() => apiUpdateNewsArticle({
      id: newsId,
      isPublished: false,
    }));

    yield put(unpublishNewsArticleSuccess(newsId));

    yield put(closeConfirmModal());

    store.addNotification({
      type: 'success',
      message: 'Новость снята с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('unpublishNewsArticleSaga ERROR', error);

    yield put(unpublishNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не снята с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS, getNewsSaga);
  yield takeEvery(actionTypes.GET_MORE_NEWS, getMoreNewsSaga);
  yield takeEvery(actionTypes.UNPUBLISH_NEWS_ARTICLE_NEWS, unpublishNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
