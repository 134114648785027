import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetTeam = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.usersUnits.item(params.usersUnitId),
});

export const apiGetDocsFolder = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docsFolders.item(params.docsFolderId),
});

export const apiGetTeamFolderDocs = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.listTeamFolder(params.usersUnitId, params.docsFolderId),
});
