export const actionTypes = {
  GET_TEAM_DOCS_WITHOUT_FOLDER: 'GET_TEAM_DOCS_WITHOUT_FOLDER',
  GET_TEAM_DOCS_WITHOUT_FOLDER_REQUEST: 'GET_TEAM_DOCS_WITHOUT_FOLDER_REQUEST',
  GET_TEAM_DOCS_WITHOUT_FOLDER_SUCCESS: 'GET_TEAM_DOCS_WITHOUT_FOLDER_SUCCESS',
  GET_TEAM_DOCS_WITHOUT_FOLDER_ERROR: 'GET_TEAM_DOCS_WITHOUT_FOLDER_ERROR',

  RESET_STATE_TEAM_DOCS_WITHOUT_FOLDER: 'RESET_STATE_TEAM_DOCS_WITHOUT_FOLDER',
};


export const resetState = () => ({ type: actionTypes.RESET_STATE_TEAM_DOCS_WITHOUT_FOLDER });

export const getDocs = (params) => ({ type: actionTypes.GET_TEAM_DOCS_WITHOUT_FOLDER, params });

export const getDocsRequest = () => ({ type: actionTypes.GET_TEAM_DOCS_WITHOUT_FOLDER_REQUEST });

export const getDocsSuccess = (params) => ({ type: actionTypes.GET_TEAM_DOCS_WITHOUT_FOLDER_SUCCESS, ...params });

export const getDocsError = (error) => ({ type: actionTypes.GET_TEAM_DOCS_WITHOUT_FOLDER_ERROR, error });
