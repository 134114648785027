export const actionTypes = {
  OPEN_ASK_CHANGELOG_MODAL: 'OPEN_ASK_CHANGELOG_MODAL',
  CLOSE_ASK_CHANGELOG_MODAL: 'CLOSE_ASK_CHANGELOG_MODAL',
};

export const open = () => ({
  type: actionTypes.OPEN_ASK_CHANGELOG_MODAL,
});

export const close = () => ({
  type: actionTypes.CLOSE_ASK_CHANGELOG_MODAL,
});
