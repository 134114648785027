export const actionTypes = {
  SET_DOCS_COMMON_PROCESS_ITEMS: 'SET_DOCS_COMMON_PROCESS_ITEMS',

  GET_DOCS_COMMON_PROCESS: 'GET_DOCS_COMMON_PROCESS',
  GET_DOCS_COMMON_PROCESS_REQUEST: 'GET_DOCS_COMMON_PROCESS_REQUEST',
  GET_DOCS_COMMON_PROCESS_SUCCESS: 'GET_DOCS_COMMON_PROCESS_SUCCESS',

  CREATE_DOCS_COMMON_PROCESS: 'CREATE_DOCS_COMMON_PROCESS',
  CREATE_DOCS_COMMON_PROCESS_REQUEST: 'CREATE_DOCS_COMMON_PROCESS_REQUEST',
  CREATE_DOCS_COMMON_PROCESS_SUCCESS: 'CREATE_DOCS_COMMON_PROCESS_SUCCESS',

  DELETE_DOCS_COMMON_PROCESS: 'DELETE_DOCS_COMMON_PROCESS',
  DELETE_DOCS_COMMON_PROCESS_REQUEST: 'DELETE_DOCS_COMMON_PROCESS_REQUEST',
  DELETE_DOCS_COMMON_PROCESS_SUCCESS: 'DELETE_DOCS_COMMON_PROCESS_SUCCESS',

  RESET_STATE_DOCS_COMMON_PROCESS: 'RESET_STATE_DOCS_COMMON_PROCESS',
};

export const resetStateDocsCommonProcess = () => ({ type: actionTypes.RESET_STATE_DOCS_COMMON_PROCESS });

export const setDocsCommonProcessItems = (params) => ({ type: actionTypes.SET_DOCS_COMMON_PROCESS_ITEMS, params });

export const getDocsCommonProcess = (params) => ({ type: actionTypes.GET_DOCS_COMMON_PROCESS, params });
export const getDocsCommonProcessRequest = () => ({ type: actionTypes.GET_DOCS_COMMON_PROCESS_REQUEST });
export const getDocsCommonProcessSuccess = (data) => ({ type: actionTypes.GET_DOCS_COMMON_PROCESS_SUCCESS, data });

export const createDocsCommonProcess = (params) => ({ type: actionTypes.CREATE_DOCS_COMMON_PROCESS, params });
export const createDocsCommonProcessRequest = () => ({ type: actionTypes.CREATE_DOCS_COMMON_PROCESS_REQUEST });
export const createDocsCommonProcessSuccess = (data) => ({ type: actionTypes.CREATE_DOCS_COMMON_PROCESS_SUCCESS, data });

export const deleteDocsCommonProcess = (params) => ({ type: actionTypes.DELETE_DOCS_COMMON_PROCESS, params });
export const deleteDocsCommonProcessRequest = () => ({ type: actionTypes.DELETE_DOCS_COMMON_PROCESS_REQUEST });
export const deleteDocsCommonProcessSuccess = (data) => ({ type: actionTypes.DELETE_DOCS_COMMON_PROCESS_SUCCESS, data });
