import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetProjectsNewsCategory,
} from './api';

import {
  actionTypes,

  getProjectsNewsCategoryRequest,
  getProjectsNewsCategorySuccess,
  getProjectsNewsCategoryError,
} from './actions';


function* getProjectsNewsCategorySaga({ id }) {
  try {
    yield put(getProjectsNewsCategoryRequest());

    const { data: { data: projectsNewsCategory } } = yield call(() => apiGetProjectsNewsCategory(id, {
      projectsNewsCategory,
    }));

    yield put(getProjectsNewsCategorySuccess(projectsNewsCategory));
  } catch (error) {
    console.log('GET PROJECTS NEWS CATEGORY ERROR', error);
    yield put(getProjectsNewsCategoryError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS_CATEGORY, getProjectsNewsCategorySaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
