import { actionTypes } from './actions';

const initialState = {
  loading: false,
  errorMessage: null,
  errorInfo: null,
};

const handleLoginRequest = state => ({
  ...state,
  loading: true,
  errorMessage: null,
  errorInfo: null,
});

const handleLoginSuccess = state => ({
  ...state,
  loading: false,
});

const handleLoginError = (state, action) => ({
  ...state,
  loading: false,
  errorMessage: action.error.message,
  errorInfo: action.error.info,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.LOGIN_REQUEST]: handleLoginRequest,
    [actionTypes.LOGIN_SUCCESS]: handleLoginSuccess,
    [actionTypes.LOGIN_ERROR]: handleLoginError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
