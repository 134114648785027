import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetUserResults = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.iSpring.userResults,
  params: {
    userIds: params.userId,
  },
});
