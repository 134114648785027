import React from 'react';
import PropTypes from 'prop-types';

import SideNav from '../SideNav';
import Footer from '../Footer';

import S from './styles';

const PrivateLayout = ({ children }) => (
  <S.Container>
    <S.Wrapper>
      <SideNav />

      <S.Content>
        {children}
      </S.Content>

      <Footer />
    </S.Wrapper>
  </S.Container>
);

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

PrivateLayout.defaultProps = {
  children: null,
};

export default PrivateLayout;
