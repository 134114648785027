import { actionTypes } from './actions';

import getCultureDocs from '../../common/getCultureDocs';

const initialState = {
  docs: [],
  cultureDocs: [],
  searchDocsRequest: false,
  searchDocsSuccess: false,
  searchDocsError: false,
};

const handleSearchDocsRequest = (state) => ({
  ...state,
  searchDocsRequest: true,
  searchDocsSuccess: false,
  searchDocsError: false,
});

const handleSearchDocsSuccess = (state, action) => {
  const {
    searchScope,
    docs,
  } = action;

  // Clone original docs data for later safe modification.
  let docsClone = docs ? [...docs]: [];

  // Split docs to groups.

  // Get Culture Docs
  let cultureDocs = [];
  if (searchScope === 'global') {
    cultureDocs = getCultureDocs({
      docs: docsClone,
    });

    // Filter out Culture Docs from all docs results.
    const cultureDocsIds = cultureDocs.map(d => d.id);
  
    docsClone = docsClone.filter((doc) => {
      const docIndex = cultureDocsIds.indexOf(doc.id);

      return docIndex === -1;
    });
  }

  let docsCloneWithAliasesResults = [...docsClone];

  docsClone.forEach(doc => {
    // Doc Aliases results should be visible in corresponding alias
    // users unit and docs folder group.
    // If found doc has aliases then we need to add this
    // doc to results again but with alias users unit and docs folder data
    if (doc.aliases && doc.aliases.length) {
      doc.aliases.forEach(docAlias => {
        let docFromAlias = {
          ...doc,
          usersUnitId: docAlias.usersUnitId,
          docsFolderId: docAlias.docsFolder?.id,
          usersUnit: docAlias.usersUnit,
          docsFolder: docAlias.docsFolder
        };

        if (doc._isExactHit) {
          docsCloneWithAliasesResults.unshift(docFromAlias);
        } else {
          docsCloneWithAliasesResults.push(docFromAlias);
        }
      });
    }
  });

  return {
    ...state,
    searchDocsRequest: false,
    searchDocsSuccess: true,
    searchDocsError: false,
    docs: docsCloneWithAliasesResults,
    cultureDocs,
  }
};

const handleSearchDocsError = (state) => ({
  ...state,
  searchDocsRequest: false,
  searchDocsSuccess: false,
  searchDocsError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_SEARCH_DOCS]: handleResetState,

    [actionTypes.SEARCH_DOCS_REQUEST]: handleSearchDocsRequest,
    [actionTypes.SEARCH_DOCS_SUCCESS]: handleSearchDocsSuccess,
    [actionTypes.SEARCH_DOCS_ERROR]: handleSearchDocsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
