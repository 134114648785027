import { actionTypes } from './actions';

const initialState = {
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: false,

  team: {},

  getDocsFolderRequest: false,
  getDocsFolderSuccess: false,
  getDocsFolderError: false,

  docsFolder: {},

  getTeamFolderDocsRequest: false,
  getTeamFolderDocsSuccess: false,
  getTeamFolderDocsError: false,

  docs: [],
};

const handleGetTeamRequest = (state) => ({
  ...state,
  getTeamRequest: true,
  getTeamSuccess: false,
  getTeamError: false,
});

const handleGetTeamSuccess = (state, action) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: true,
  getTeamError: false,
  team: action.team,
});

const handleGetTeamError = (state) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: true,
});

const handleResetStateTeam = (state) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: false,
  team: {},
});


const handleGetDocsFolderRequest = (state) => ({
  ...state,
  getDocsFolderRequest: true,
  getDocsFolderSuccess: false,
  getDocsFolderError: false,
});

const handleGetDocsFolderSuccess = (state, action) => ({
  ...state,
  getDocsFolderRequest: false,
  getDocsFolderSuccess: true,
  getDocsFolderError: false,
  docsFolder: action.docsFolder,
});

const handleGetDocsFolderError = (state) => ({
  ...state,
  getDocsFolderRequest: false,
  getDocsFolderSuccess: false,
  getDocsFolderError: true,
});

const handleResetStateDocsFolder = (state) => ({
  ...state,
  getDocsFolderRequest: false,
  getDocsFolderSuccess: false,
  getDocsFolderError: false,
  docsFolder: {},
});


const handleGetTeamFolderDocsRequest = (state) => ({
  ...state,
  getTeamFolderDocsRequest: true,
  getTeamFolderDocsSuccess: false,
  getTeamFolderDocsError: false,
});

const handleGetTeamFolderDocsSuccess = (state, action) => ({
  ...state,
  getTeamFolderDocsRequest: false,
  getTeamFolderDocsSuccess: true,
  getTeamFolderDocsError: false,
  docs: action.docs,
});

const handleGetTeamDocsFolderDocsError = (state) => ({
  ...state,
  getTeamFolderDocsRequest: false,
  getTeamFolderDocsSuccess: false,
  getTeamFolderDocsError: true,
});

const handleResetStateTeamDocs = (state) => ({
  ...state,
  getTeamFolderDocsRequest: false,
  getTeamFolderDocsSuccess: false,
  getTeamFolderDocsError: false,
  docs: [],
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_TEAM_2]: handleResetStateTeam,

    [actionTypes.GET_TEAM_2_REQUEST]: handleGetTeamRequest,
    [actionTypes.GET_TEAM_2_SUCCESS]: handleGetTeamSuccess,
    [actionTypes.GET_TEAM_2_ERROR]: handleGetTeamError,


    [actionTypes.RESET_STATE_DOCS_FOLDER_2]: handleResetStateDocsFolder,

    [actionTypes.GET_DOCS_FOLDER_REQUEST_2]: handleGetDocsFolderRequest,
    [actionTypes.GET_DOCS_FOLDER_SUCCESS_2]: handleGetDocsFolderSuccess,
    [actionTypes.GET_DOCS_FOLDER_ERROR_2]: handleGetDocsFolderError,


    [actionTypes.RESET_STATE_TEAM_DOCS]: handleResetStateTeamDocs,

    [actionTypes.GET_TEAM_FOLDER_DOCS_REQUEST]: handleGetTeamFolderDocsRequest,
    [actionTypes.GET_TEAM_FOLDER_DOCS_SUCCESS]: handleGetTeamFolderDocsSuccess,
    [actionTypes.GET_TEAM_FOLDER_DOCS_ERROR]: handleGetTeamDocsFolderDocsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
