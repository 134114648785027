export const actionTypes = {
  GET_NEWS: 'GET_NEWS',
  GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_ERROR: 'GET_NEWS_ERROR',

  GET_MORE_NEWS: 'GET_MORE_NEWS',
  GET_MORE_NEWS_REQUEST: 'GET_MORE_NEWS_REQUEST',
  GET_MORE_NEWS_SUCCESS: 'GET_MORE_NEWS_SUCCESS',
  GET_MORE_NEWS_ERROR: 'GET_MORE_NEWS_ERROR',

  UNPUBLISH_NEWS_ARTICLE_NEWS: 'UNPUBLISH_NEWS_ARTICLE_NEWS',
  UNPUBLISH_NEWS_ARTICLE_NEWS_REQUEST: 'UNPUBLISH_NEWS_ARTICLE_NEWS_REQUEST',
  UNPUBLISH_NEWS_ARTICLE_NEWS_SUCCESS: 'UNPUBLISH_NEWS_ARTICLE_NEWS_SUCCESS',

  CHANGE_OFFSET_NEWS: 'CHANGE_OFFSET_NEWS',
  RESET_STATE_NEWS: 'RESET_STATE_NEWS',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET_NEWS, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS });


export const getNews = (params) => ({ type: actionTypes.GET_NEWS, params });

export const getNewsRequest = () => ({ type: actionTypes.GET_NEWS_REQUEST });

export const getNewsSuccess = (params) => ({ type: actionTypes.GET_NEWS_SUCCESS, ...params });

export const getNewsError = (error) => ({ type: actionTypes.GET_NEWS_ERROR, error });


// pagination
export const getMoreNews = (params) => ({ type: actionTypes.GET_MORE_NEWS, ...params });

export const getMoreNewsRequest = () => ({ type: actionTypes.GET_MORE_NEWS_REQUEST });

export const getMoreNewsSuccess = (params) => ({
  type: actionTypes.GET_MORE_NEWS_SUCCESS, ...params,
});

export const getMoreNewsError = (error) => ({ type: actionTypes.GET_MORE_NEWS_ERROR, error });

export const unpublishNewsArticle = (newsId) => ({ type: actionTypes.UNPUBLISH_NEWS_ARTICLE_NEWS, newsId });
export const unpublishNewsArticleRequest = () => ({ type: actionTypes.UNPUBLISH_NEWS_ARTICLE_NEWS_REQUEST });
export const unpublishNewsArticleSuccess = (newsId) => ({ type: actionTypes.UNPUBLISH_NEWS_ARTICLE_NEWS_SUCCESS, newsId });
