import styled from "styled-components";
import { styleHelpers, media } from "@pik/pik-ui";

const S = {};

S.Text  = styled.div`
  position: relative;
  top: 1px;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  color: #000;
  user-select: none;
  transition: color 0.2s ease;
`;

S.Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #fff;
  padding: 0 24px;
  border-radius: 1000px;
  text-decoration: none;

  ${({ isInSideNav }) => isInSideNav && `
    background: #F6F5F5;
  `}

  &:hover, &:active {
    ${S.Text} {
      color: ${styleHelpers.colors.primary};
    }
  }
`;

S.Icon = styled.div`
  position: relative;
  margin-right: 10px;
  width: 16px;
  height: 16px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${styleHelpers.colors.primary};
    stroke: none !important;
  }

  @media ${media.mobile} {
    margin-right: 0;
  }
`;

export default S;
