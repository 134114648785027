import { actionTypes } from './actions';

const initialState = {
  docsCommonProcess: null,
  getDocsCommonProcessRequest: false,
  createDocsCommonProcessRequest: false,
  deleteDocsCommonProcessRequest: false,
};

const handleSetDocsCommonProcessItems = (state, action) => {
  return {
    ...state,
    docsCommonProcess: {
      ...state.docsCommonProcess,
      items: action.params.items
    }
  }
};

const handleGetDocsCommonProcessRequest = (state) => ({
  ...state,
  getDocsCommonProcessRequest: true,
});

const handleGetCommonProcessSuccess = (state, action) => ({
  ...state,
  getDocsCommonProcessRequest: false,
  docsCommonProcess: action.data,
});

const handleCreateDocsCommonProcessRequest = (state) => ({
  ...state,
  createDocsCommonProcessRequest: true,
});

const handleCreateCommonProcessSuccess = (state, action) => ({
  ...state,
  createDocsCommonProcessRequest: false,
  docsCommonProcess: action.data,
});

const handleDeleteDocsCommonProcessRequest = (state) => ({
  ...state,
  deleteDocsCommonProcessRequest: true,
});

const handleDeleteCommonProcessSuccess = (state, action) => ({
  ...state,
  deleteDocsCommonProcessRequest: false,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_COMMON_PROCESS]: handleResetState,
    [actionTypes.SET_DOCS_COMMON_PROCESS_ITEMS]: handleSetDocsCommonProcessItems,
    [actionTypes.CREATE_DOCS_COMMON_PROCESS_REQUEST]: handleCreateDocsCommonProcessRequest,
    [actionTypes.CREATE_DOCS_COMMON_PROCESS_SUCCESS]: handleCreateCommonProcessSuccess,
    [actionTypes.GET_DOCS_COMMON_PROCESS_REQUEST]: handleGetDocsCommonProcessRequest,
    [actionTypes.GET_DOCS_COMMON_PROCESS_SUCCESS]: handleGetCommonProcessSuccess,
    [actionTypes.DELETE_DOCS_COMMON_PROCESS_REQUEST]: handleDeleteDocsCommonProcessRequest,
    [actionTypes.DELETE_DOCS_COMMON_PROCESS_SUCCESS]: handleDeleteCommonProcessSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
