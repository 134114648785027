export const actionTypes = {
  GET_NEWS_UNPUBLISHED: 'GET_NEWS_UNPUBLISHED',
  GET_NEWS_UNPUBLISHED_REQUEST: 'GET_NEWS_REQUEST',
  GET_NEWS_UNPUBLISHED_SUCCESS: 'GET_NEWS_UNPUBLISHED_SUCCESS',
  GET_NEWS_UNPUBLISHED_ERROR: 'GET_NEWS_UNPUBLISHED_ERROR',

  GET_MORE_NEWS_UNPUBLISHED: 'GET_MORE_NEWS_UNPUBLISHED',
  GET_MORE_NEWS_UNPUBLISHED_REQUEST: 'GET_MORE_NEWS_UNPUBLISHED_REQUEST',
  GET_MORE_NEWS_UNPUBLISHED_SUCCESS: 'GET_MORE_NEWS_UNPUBLISHED_SUCCESS',
  GET_MORE_NEWS_UNPUBLISHED_ERROR: 'GET_MORE_NEWS_UNPUBLISHED_ERROR',

  DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED: 'DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED',
  DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_REQUEST: 'DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_REQUEST',
  DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_SUCCESS: 'DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_SUCCESS',

  CHANGE_OFFSET_NEWS_UNPUBLISHED: 'CHANGE_OFFSET_NEWS_UNPUBLISHED',
  RESET_STATE_NEWS_UNPUBLISHED: 'RESET_STATE_NEWS_UNPUBLISHED',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET_NEWS_UNPUBLISHED, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_UNPUBLISHED });


export const getNews = (params) => ({ type: actionTypes.GET_NEWS_UNPUBLISHED, params });

export const getNewsRequest = () => ({ type: actionTypes.GET_NEWS_UNPUBLISHED_REQUEST });

export const getNewsSuccess = (params) => ({ type: actionTypes.GET_NEWS_UNPUBLISHED_SUCCESS, ...params });

export const getNewsError = (error) => ({ type: actionTypes.GET_NEWS_UNPUBLISHED_ERROR, error });


// pagination
export const getMoreNews = (params) => ({ type: actionTypes.GET_MORE_NEWS_UNPUBLISHED, ...params });

export const getMoreNewsRequest = () => ({ type: actionTypes.GET_MORE_NEWS_UNPUBLISHED_REQUEST });

export const getMoreNewsSuccess = (params) => ({
  type: actionTypes.GET_MORE_NEWS_UNPUBLISHED_SUCCESS, ...params,
});

export const getMoreNewsError = (error) => ({ type: actionTypes.GET_MORE_NEWS_UNPUBLISHED_ERROR, error });

export const deleteNewsArticle = (newsId) => ({ type: actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED, newsId });
export const deleteNewsArticleRequest = () => ({ type: actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_REQUEST });
export const deleteNewsArticleSuccess = (newsId) => ({ type: actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_SUCCESS, newsId });
