export const actionTypes = {
  GET_CULTURE_DOCS_UNPUBLISHED: 'GET_CULTURE_DOCS_UNPUBLISHED',
  GET_CULTURE_DOCS_UNPUBLISHED_REQUEST: 'GET_CULTURE_DOCS_UNPUBLISHED_REQUEST',
  GET_CULTURE_DOCS_UNPUBLISHED_SUCCESS: 'GET_CULTURE_DOCS_UNPUBLISHED_SUCCESS',
  GET_CULTURE_DOCS_UNPUBLISHED_ERROR: 'GET_CULTURE_DOCS_UNPUBLISHED_ERROR',

  DELETE_DOC_CULTURE_DOCS_UNPUBLISHED: 'DELETE_DOC_CULTURE_DOCS_UNPUBLISHED',
  DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_REQUEST: 'DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_REQUEST',
  DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_SUCCESS: 'DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_SUCCESS',

  RESET_STATE_CULTURE_DOCS_UNPUBLISHED: 'RESET_STATE_CULTURE_DOCS_UNPUBLISHED',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_CULTURE_DOCS_UNPUBLISHED });

export const getCultureDocsUnpublished = (params) => ({ type: actionTypes.GET_CULTURE_DOCS_UNPUBLISHED, params });
export const getCultureDocsUnpublishedRequest = () => ({ type: actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_REQUEST });
export const getCultureDocsUnpublishedSuccess = (params) => ({ type: actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_SUCCESS, ...params });
export const getCultureDocsUnpublishedError = (error) => ({ type: actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_ERROR, error });

export const deleteDoc = (docId) => ({ type: actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED, docId });
export const deleteDocRequest = () => ({ type: actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_REQUEST });
export const deleteDocSuccess = (docId) => ({ type: actionTypes.DELETE_DOC_CULTURE_DOCS_UNPUBLISHED_SUCCESS, docId });
