export const actionTypes = {
  SHOW_ERROR_POPUP: 'SHOW_ERROR_POPUP',
  HIDE_ERROR_POPUP: 'HIDE_ERROR_POPUP',
  SEND_EMAIL_ERROR_POPUP: 'SEND_EMAIL_ERROR_POPUP',
  SEND_EMAIL_REQUEST_ERROR_POPUP: 'SEND_EMAIL_REQUEST_ERROR_POPUP',
  SEND_EMAIL_SUCCESS_ERROR_POPUP: 'SEND_EMAIL_SUCCESS_ERROR_POPUP',
  SEND_EMAIL_ERROR_ERROR_POPUP: 'SEND_EMAIL_ERROR_ERROR_POPUP',
};

export const showPopup = () => ({ type: actionTypes.SHOW_ERROR_POPUP });
export const hidePopup = () => ({ type: actionTypes.HIDE_ERROR_POPUP });

export const sendEmail = (data) => ({ type: actionTypes.SEND_EMAIL_ERROR_POPUP, data });

export const sendEmailRequest = () => ({ type: actionTypes.SEND_EMAIL_REQUEST_ERROR_POPUP });

export const sendEmailSuccess = () => ({ type: actionTypes.SEND_EMAIL_SUCCESS_ERROR_POPUP });

export const sendEmailError = (error) => ({ type: actionTypes.SEND_EMAIL_ERROR_ERROR_POPUP, error });
