export const actionTypes = {
  GET_ISPRING_USERS: 'GET_ISPRING_USERS',
  GET_ISPRING_USERS_REQUEST: 'GET_ISPRING_USERS_REQUEST',
  GET_ISPRING_USERS_SUCCESS: 'GET_ISPRING_USERS_SUCCESS',
  GET_ISPRING_USERS_ERROR: 'GET_ISPRING_USERS_ERROR',

  RESET_STATE_ISPRING_USERS: 'RESET_STATE_ISPRING_USERS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_ISPRING_USERS });

export const getUsers = (params) => ({ type: actionTypes.GET_ISPRING_USERS, params });

export const getUsersRequest = () => ({ type: actionTypes.GET_ISPRING_USERS_REQUEST });

export const getUsersSuccess = (params) => ({ type: actionTypes.GET_ISPRING_USERS_SUCCESS, ...params });

export const getUsersError = (error) => ({ type: actionTypes.GET_ISPRING_USERS_ERROR, error });
