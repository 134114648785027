import { actionTypes } from './actions';

const initialState = {
  getUsersRequest: false,
  getUsersSuccess: false,
  users: []
};

const handleGetUsersRequest = (state) => ({
  ...state,
  getUsersRequest: true,
  getUsersSuccess: false,
});

const handleGetUsersSuccess = (state, action) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: true,
  users: action.users,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_USERS_AI_REQUEST]: handleGetUsersRequest,
    [actionTypes.GET_USERS_AI_SUCCESS]: handleGetUsersSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
