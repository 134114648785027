import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

// import {
//   addDocsFolder,
// } from '../docsFolders/actions';

import {
  apiCreateDocsFolder,
  apiUpdateDocsFolder,
  apiGetDocsFolder,
  apiDeleteDocsFolder,
} from './api';

import {
  actionTypes,
  getDocsFolderRequest,
  getDocsFolderSuccess,
  getDocsFolderError,
  submitDocsFolderRequest,
  submitDocsFolderSuccess,
} from './actions';


function* createDocsFolderSaga(action) {
  try {
    console.log('createDocsFolderSaga');
    console.log('action.params', action.params);

    yield put(submitDocsFolderRequest());

    const { data: { data: docsFolder } } = yield call(() => apiCreateDocsFolder(action.params.data));

    yield put(submitDocsFolderSuccess());

    // yield put(addDocsFolder(docsFolder));

    if (action.params.successCallback) {
      action.params.successCallback();
    }

    console.log('created docsFolder', docsFolder);
  } catch (error) {
    console.log('CREATE DOCS_FOLDER ERROR', error);
  }
}

function* updateDocsFolderSaga(action) {
  try {
    console.log('updateDocsFolderSaga');
    console.log('action.params', action.params);

    const docsFolderToCreate = action.params.data;

    yield put(submitDocsFolderRequest());

    const { data: { data: docsFolder } } = yield call(() => apiUpdateDocsFolder(docsFolderToCreate));

    yield put(submitDocsFolderSuccess());

    console.log('updated docsFolder', docsFolder);

    if (action.params.successCallback) {
      action.params.successCallback();
    }
  } catch (error) {
    console.log('UPDATE DOCS_FOLDER ERROR', error);
  }
}

function* getDocsFolderSaga({ id }) {
  try {
    yield put(getDocsFolderRequest());

    const { data: { data: docsFolder } } = yield call(() => apiGetDocsFolder(id));

    yield put(getDocsFolderSuccess(docsFolder));
  } catch (error) {
    console.log('GET DOCS_FOLDER ERROR', error);
    yield put(getDocsFolderError(error));
  }
}

function* deleteDocsFolderSaga(action) {
  try {
    yield call(() => apiDeleteDocsFolder(action.params.id));

    if (action.params.successCallback) {
      action.params.successCallback();
    }
  } catch (error) {
    console.log('deleteDocsFolderSaga ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.CREATE_DOCS_FOLDER, createDocsFolderSaga);
  yield takeEvery(actionTypes.UPDATE_DOCS_FOLDER, updateDocsFolderSaga);
  yield takeEvery(actionTypes.GET_DOCS_FOLDER, getDocsFolderSaga);
  yield takeEvery(actionTypes.DELETE_DOCS_FOLDER, deleteDocsFolderSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
