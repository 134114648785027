const makeCommonRoute = (path) => ({
  list: `/${path}`,
  item: (id = '[id]') => `/${path}/${id}`,
});

const urls = {
  login: '/login',
  logout: '/logout',
  news: {
    list: '/',
    unpublishedList: '/news/unpublished',
    champions: '/news/champions',
    item: (id = '[id]') => `/news/${id}`,
    itemChampions: (id = '[id]') => `/news/champions/${id}`,
  },
  usersList: '/users',
  newsCreate: '/news/create',
  championsCreate: '/news/champions/create',
  projectsNews: makeCommonRoute('docs/projects'),
  projectsNewsCreate: '/docs/projects/create',
  projectsNewsCategories: makeCommonRoute('docs/projects-categories'),
  docs: makeCommonRoute('docs'),
  docsCreate: '/docs/create',
  cultureDocs: {
    list: 'docs/culture',
    unpublishedList: 'docs/culture/unpublished',
    item: (id = '[id]') => `/docs/culture/${id}`,
  },
  teamDocs: {
    team: (usersUnitId = '[usersUnitId]') => `/docs/teams/${usersUnitId}`,
    teamDocsFolder: (usersUnitId = '[usersUnitId]', docsFolderId = '[docsFolderId]') => `/docs/teams/${usersUnitId}/folders/${docsFolderId}`,
  },
  tests: makeCommonRoute('tests'),
  users: makeCommonRoute('users'),
  learn: makeCommonRoute('learn'),
  learnCatalog: '/learn/CoursesCatalog',
  teams: '/teams',
  verficationDocs: '/verficationDocs',
  useful: {
    index: '/useful',
    profile: {
      index: '/useful/profile',
    },
    standarts: {
      index: '/useful/standarts',
      team: (usersUnitId = '[usersUnitId]') => `/useful/standarts/team/${usersUnitId}`,
    },
    avatars: '/useful/avatars',
    voice: '/useful/voice',
    culture: '/useful/culture',
  },
  ai: {
    usersList: '/ai-users',
  },
};

export default urls;
