import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import Router from 'next/router';

import urls from '../../common/urls';

import {
  apiCreateNewsArticle,
  apiUpdateNewsArticle,
  apiGetNewsArticle,
  apiDeleteNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsArticleRequest,
  getNewsArticleSuccess,
  getNewsArticleError,
  saveNewsArticleRequest,
  saveNewsArticleSuccess,
  saveNewsArticleError,
  saveAndPublishNewsArticleRequest,
  saveAndPublishNewsArticleSuccess,
  saveAndPublishNewsArticleError,
  deleteNewsArticleRequest,
  deleteNewsArticleSuccess,
} from './actions';


function* createNewsArticleSaga(action) {
  try {
    console.log('createNewsArticleSaga');
    console.log('action.params', action.params);

    const data = action.params;
    const isPublishedChange = data.isPublished === true || data.isPublished === false;

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleRequest());
    } else {
      yield put(saveNewsArticleRequest());
    }

    const { data: { data: newsArticle } } = yield call(() => apiCreateNewsArticle(data));

    console.log('created newsArticle', newsArticle);

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleSuccess({ isPublished: data.isPublished }));
    } else {
      yield put(saveNewsArticleSuccess());
    }

    store.addNotification({
      type: 'success',
      message: 'Проект сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });

    Router.push(urls.projectsNews.item(), urls.projectsNews.item(newsArticle.id));
  } catch (error) {
    console.log('CREATE NEWS ARTICLE ERROR', error);

    yield put(saveAndPublishNewsArticleError());
    yield put(saveNewsArticleError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Проект не сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* updateNewsArticleSaga(action) {
  try {
    console.log('updateNewsArticleSaga');
    console.log('action.params', action.params);

    const data = action.params;
    const isPublishedChange = data.isPublished === true || data.isPublished === false;

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleRequest());
    } else {
      yield put(saveNewsArticleRequest());
    }

    const { data: { data: newsArticle } } = yield call(() => apiUpdateNewsArticle(data));

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleSuccess({ isPublished: data.isPublished }));
    } else {
      yield put(saveNewsArticleSuccess());
    }

    console.log('updated newsArticle', newsArticle);

    store.addNotification({
      type: 'success',
      message: 'Проект сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('UPDATE NEWS ARTICLE ERROR', error);

    yield put(saveAndPublishNewsArticleError());
    yield put(saveNewsArticleError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Проект не сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* getNewsArticleSaga({ id }) {
  try {
    yield put(getNewsArticleRequest());

    const { data: { data: newsArticle } } = yield call(() => apiGetNewsArticle(id));

    const projectsNewsArticle = newsArticle;

    yield put(getNewsArticleSuccess(projectsNewsArticle));
  } catch (error) {
    console.log('GET NEWS ARTICLE ERROR', error);
    yield put(getNewsArticleError(error));
  }
}

function* deleteNewsArticleSaga({ newsId }) {
  console.log('deleteNewsArticleSaga', newsId);

  try {
    yield put(deleteNewsArticleRequest());

    yield call(() => apiDeleteNewsArticle(newsId));

    yield put(deleteNewsArticleSuccess());

    Router.push(urls.docs.list + '#projects');

    store.addNotification({
      type: 'success',
      message: 'Проект удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('deleteNewsArticleSaga ERROR', error);

    yield put(deleteNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Проект не удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.CREATE_PROJECTS_NEWS_ARTICLE, createNewsArticleSaga);
  yield takeEvery(actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE, updateNewsArticleSaga);
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS_ARTICLE, getNewsArticleSaga);
  yield takeEvery(actionTypes.DELETE_PROJECTS_NEWS_ARTICLE, deleteNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
