import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  close as closeConfirmModal
} from '../confirmModal/actions';

import {
  apiGetNews,
  apiDeleteNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsRequest,
  getNewsSuccess,
  getNewsError,

  getMoreNewsRequest,
  getMoreNewsSuccess,
  getMoreNewsError,

  deleteNewsArticleRequest,
  deleteNewsArticleSuccess,
} from './actions';


function* getNewsSaga(data) {
  try {
    yield put(getNewsRequest());
    const { offset, limit } = yield select(({ unpublishedNewsService }) => unpublishedNewsService);

    const { data: { data: news } } = yield call(() => apiGetNews({
      isPublished: false,
      offset,
      limit: data.params.limit || limit,
    }));

    yield put(getNewsSuccess({
      news,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET UNPUBLISHED NEWS ERROR', error);
    yield put(getNewsError(error));
  }
}

function* getMoreNewsSaga({ search }) {
  try {
    yield put(getMoreNewsRequest());
    const { offset, limit } = yield select(({ unpublishedNewsService }) => unpublishedNewsService);
    const params = {
      isPublished: false,
      offset,
      limit
    };

    if (search && search.length) {
      params.search = search;
    }

    const { data: { data: news } } = yield call(() => apiGetNews(params));

    yield put(getMoreNewsSuccess({
      news,
      offset: offset + limit,
      isNoMoreNews: !news.length,
    }));
  } catch (error) {
    console.log('GET MORE UNPUBLISHED NEWS ERROR', error);
    yield put(getMoreNewsError(error));
  }
}

function* deleteNewsArticleSaga({ newsId }) {
  try {
    yield put(deleteNewsArticleRequest());

    yield call(() => apiDeleteNewsArticle(newsId));

    yield put(deleteNewsArticleSuccess(newsId));

    yield put(closeConfirmModal());

    store.addNotification({
      type: 'success',
      message: 'Новость удалена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('deleteNewsArticleSaga ERROR', error);

    yield put(deleteNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не удалена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_UNPUBLISHED, getNewsSaga);
  yield takeEvery(actionTypes.GET_MORE_NEWS_UNPUBLISHED, getMoreNewsSaga);
  yield takeEvery(actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED, deleteNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
