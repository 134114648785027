import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { utcToZonedTime, format } from "date-fns-tz";

import apiUrls from "../../../common/apiServices";

import {
  getLoginUrl,
} from '../../../services/ai/actions';

import {
  setUserData,
} from '../../../services/user/actions';

import localIcons from './icons';

import S from './styles';

function AILoginButton(props) {
  const {
    user,
    loginUrl,
    loginUrlRequest,
    isInSideNav,
    getLoginUrlAction,
    setUserDataAction
  } = props;

  useEffect(() => {
    if (!loginUrl && !loginUrlRequest && user) {
      getLoginUrlAction({ userGlobalId: user.globalId });
    }
  }, [loginUrl, loginUrlRequest, user]);

  return (
    <S.Container
      target="_blank"
      href={loginUrl}
      isInSideNav={isInSideNav}
      onClick={() => {
        if (!user.isStartedUsingAI) {
          // Right away to prevent calling on next clicks
          setUserDataAction({
            user: {
              isStartedUsingAI: true  
            }
          }); 

          const date = new Date();
          const timeZone = "Europe/Moscow";
          const zonedDate = utcToZonedTime(date, timeZone);
          const startedUsingAIDate = format(zonedDate, "y-LL-dd HH:mm:ss");

          axios({
            method: "PUT",
            url: apiUrls.clientApi.users.setIsStartedUsingAI,
            data: {
              userGlobalId: user.globalId,
              startedUsingAIDate
            }
          });

          setUserDataAction({
            user: {
              isStartedUsingAI: true  
            }
          })
        }
      }}
    >
      <S.Icon>{ localIcons.wand }</S.Icon>
      <S.Text>AI платформа</S.Text>
    </S.Container>
  );
}

const mapDispatchToProps = {
  getLoginUrlAction: getLoginUrl,
  setUserDataAction: setUserData,
};

const mapStateToProps = ({ userService, aiService }) => ({
  user: userService.user,
  loginUrl: aiService.loginUrl,
  loginUrlRequest: aiService.loginUrlRequest,
});

export default connect(mapStateToProps, mapDispatchToProps)(AILoginButton);
