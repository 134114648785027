import axios from 'axios';
import apiUrls from '../../common/apiServices';


export const apiGetUsersUnits = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.usersUnits.list,
  params: {
    ...params,
  },
});
