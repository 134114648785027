import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetProjectsNewsCategories,
} from './api';

import {
  actionTypes,

  getProjectsNewsCategoriesRequest,
  getProjectsNewsCategoriesSuccess,
  getProjectsNewsCategoriesError,
} from './actions';


function* getProjectsNewsCategoriesSaga(data) {
  try {
    yield put(getProjectsNewsCategoriesRequest());

    const { data: { data: docsCategories } } = yield call(() => apiGetProjectsNewsCategories());

    yield put(getProjectsNewsCategoriesSuccess(docsCategories));
  } catch (error) {
    console.log('GET PROJECTS NEWS CATEGORIES ERROR', error);
    yield put(getProjectsNewsCategoriesError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS_CATEGORIES, getProjectsNewsCategoriesSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
