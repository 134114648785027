export const actionTypes = {
  GET_ISPRING_COURSES: 'GET_ISPRING_COURSES',
  GET_ISPRING_COURSES_REQUEST: 'GET_ISPRING_COURSES_REQUEST',
  GET_ISPRING_COURSES_SUCCESS: 'GET_ISPRING_COURSES_SUCCESS',
  GET_ISPRING_COURSES_ERROR: 'GET_ISPRING_COURSES_ERROR',

  RESET_STATE_ISPRING_COURSES: 'RESET_STATE_ISPRING_COURSES',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_ISPRING_COURSES });

export const getCourses = (params) => ({ type: actionTypes.GET_ISPRING_COURSES, params });

export const getCoursesRequest = () => ({ type: actionTypes.GET_ISPRING_COURSES_REQUEST });

export const getCoursesSuccess = (params) => ({ type: actionTypes.GET_ISPRING_COURSES_SUCCESS, ...params });

export const getCoursesError = (error) => ({ type: actionTypes.GET_ISPRING_COURSES_ERROR, error });
