export const actionTypes = {
  CREATE_DOC: 'CREATE_DOC',
  UPDATE_DOC: 'UPDATE_DOC',

  GET_DOC: 'GET_DOC',
  GET_DOC_REQUEST: 'GET_DOC_REQUEST',
  GET_DOC_SUCCESS: 'GET_DOC_SUCCESS',
  GET_DOC_ERROR: 'GET_DOC_ERROR',

  DELETE_DOC: 'DELETE_DOC',

  SAVE_DOC_REQUEST: 'SAVE_DOC_REQUEST',
  SAVE_DOC_SUCCESS: 'SAVE_DOC_SUCCESS',
  SAVE_DOC_ERROR: 'SAVE_DOC_ERROR',

  SAVE_AND_PUBLISH_DOC_REQUEST: 'SAVE_AND_PUBLISH_DOC_REQUEST',
  SAVE_AND_PUBLISH_DOC_SUCCESS: 'SAVE_AND_PUBLISH_DOC_SUCCESS',
  SAVE_AND_PUBLISH_DOC_ERROR: 'SAVE_AND_PUBLISH_DOC_ERROR',

  DELETE_DOC_REQUEST: 'DELETE_DOC_REQUEST',
  DELETE_DOC_SUCCESS: 'DELETE_DOC_SUCCESS',

  RESET_STATE_DOC_EDITOR: 'RESET_STATE_DOC_EDITOR',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_DOC_EDITOR });

export const createDoc = (params, changelog, isCopy, originalDocId) => ({
  type: actionTypes.CREATE_DOC,
  params,
  changelog,
  isCopy,
  originalDocId
});

export const updateDoc = (
  articleData,
  changelog,
  docsCommonProcess,
  oldDocBlocks,
  removingBlocks,
  actualDate,
) => ({
  type: actionTypes.UPDATE_DOC,
  articleData,
  changelog,
  docsCommonProcess,
  oldDocBlocks,
  removingBlocks,
  actualDate,
});

export const getDoc = (id) => ({ type: actionTypes.GET_DOC, id });

export const getDocRequest = () => ({ type: actionTypes.GET_DOC_REQUEST });

export const getDocSuccess = (data) => ({
  type: actionTypes.GET_DOC_SUCCESS,
  data,
});

export const getDocError = (error) => ({
  type: actionTypes.GET_DOC_ERROR,
  error,
});

export const saveDocRequest = () => ({ type: actionTypes.SAVE_DOC_REQUEST });
export const saveDocSuccess = () => ({ type: actionTypes.SAVE_DOC_SUCCESS });
export const saveDocError = () => ({ type: actionTypes.SAVE_DOC_ERROR });

export const saveAndPublishDocRequest = () => ({
  type: actionTypes.SAVE_AND_PUBLISH_DOC_REQUEST,
});
export const saveAndPublishDocSuccess = (data) => ({
  type: actionTypes.SAVE_AND_PUBLISH_DOC_SUCCESS,
  data,
});
export const saveAndPublishDocError = () => ({
  type: actionTypes.SAVE_AND_PUBLISH_DOC_ERROR,
});

export const deleteDoc = (doc) => ({ type: actionTypes.DELETE_DOC, doc });
export const deleteDocRequest = () => ({
  type: actionTypes.DELETE_DOC_REQUEST,
});
export const deleteDocSuccess = () => ({
  type: actionTypes.DELETE_DOC_SUCCESS,
});
