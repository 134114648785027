import localIcons from "./icons";

import S from "./styles";

const Footer = () => (
  <S.Container id="footer">
    <S.Content>
      <S.Blocks>
        <S.ContactsBlock>
          <S.Block>
            Напишите нам <br />{" "}
            <S.Link href="mailto:tuzovass@pik.ru" target="_blank">
              tuzovass@pik.ru
            </S.Link>
            {localIcons.mail}
          </S.Block>
          <S.Block>
            Больше новостей в нашем канале <br />{" "}
            <S.Link 
              href={process.env.TG_LIVE_CHANNEL_URL} 
              target="_blank"
            >
              Телеграм
            </S.Link>
            {localIcons.telegram}
          </S.Block>
        </S.ContactsBlock>

        <S.Block astro>
          ПАО ПИК, {new Date().getFullYear()} <br /> Powered by{" "}
          <S.Link href="https://astroshock.ru/" target="_blank">
            Astroshock
          </S.Link>
        </S.Block>
      </S.Blocks>
    </S.Content>
  </S.Container>
);

export default Footer;
