export const actionTypes = {
  SEARCH_DOCS: 'SEARCH_DOCS',
  SEARCH_DOCS_REQUEST: 'SEARCH_DOCS_REQUEST',
  SEARCH_DOCS_SUCCESS: 'SEARCH_DOCS_SUCCESS',
  SEARCH_DOCS_ERROR: 'SEARCH_DOCS_ERROR',

  RESET_STATE_SEARCH_DOCS: 'RESET_STATE_SEARCH_DOCS',
};

export const resetStateSearchDocs = () => ({ type: actionTypes.RESET_STATE_SEARCH_DOCS });

export const searchDocs = (params) => ({ type: actionTypes.SEARCH_DOCS, ...params });

export const searchDocsRequest = () => ({ type: actionTypes.SEARCH_DOCS_REQUEST });

export const searchDocsSuccess = (params) => ({ type: actionTypes.SEARCH_DOCS_SUCCESS, ...params });

export const searchDocsError = (error) => ({ type: actionTypes.SEARCH_DOCS_ERROR, error });
