import { actionTypes } from './actions';

const initialState = {
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: false,

  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,

  newsList: [],

  limit: 20,
  offset: 0,
  isNoMoreNews: false,

  deleteNewsArticleRequest: false,
};

const handleGetNewsRequest = (state) => ({
  ...state,
  getNewsRequest: true,
  getNewsSuccess: false,
  getNewsError: false,
});

const handleGetNewsSuccess = (state, action) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: true,
  getNewsError: false,
  newsList: action.news,
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetNewsError = (state) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: true,
});


const handleGetMoreNewsRequest = (state) => ({
  ...state,
  getMoreNewsRequest: true,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,
});

const handleGetMoreNewsSuccess = (state, action) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: true,
  getMoreNewsError: false,
  newsList: [...state.newsList, ...action.news],
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetMoreNewsError = (state) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: true,
});

const handleResetState = () => ({
  ...initialState,
});

const handleChangeOffset = (state, action) => ({
  ...state,
  offset: action.offset,
});

const handleDeleteNewsArticleRequest = (state) => ({
  ...state,
  deleteNewsArticleRequest: true,
});

const handleDeleteNewsArticleSuccess = (state, action) => {
  const _newsList = [...state.newsList];

  const newsArticleIndex = _newsList.findIndex((newsArticle) => {
    return newsArticle.id === action.newsId;
  });

  _newsList.splice(newsArticleIndex, 1);

  return {
    ...state,
    newsList: _newsList,
    deleteNewsArticleRequest: false,
  }
};

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_UNPUBLISHED]: handleResetState,
    [actionTypes.CHANGE_OFFSET_NEWS_UNPUBLISHED]: handleChangeOffset,

    [actionTypes.GET_NEWS_UNPUBLISHED_REQUEST]: handleGetNewsRequest,
    [actionTypes.GET_NEWS_UNPUBLISHED_SUCCESS]: handleGetNewsSuccess,
    [actionTypes.GET_NEWS_UNPUBLISHED_ERROR]: handleGetNewsError,

    [actionTypes.GET_MORE_NEWS_UNPUBLISHED_REQUEST]: handleGetMoreNewsRequest,
    [actionTypes.GET_MORE_NEWS_UNPUBLISHED_SUCCESS]: handleGetMoreNewsSuccess,
    [actionTypes.GET_MORE_NEWS_UNPUBLISHED_ERROR]: handleGetMoreNewsError,

    [actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_REQUEST]: handleDeleteNewsArticleRequest,
    [actionTypes.DELETE_NEWS_ARTICLE_NEWS_UNPUBLISHED_SUCCESS]: handleDeleteNewsArticleSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
