import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetUsersUnits,
} from './api';

import {
  actionTypes,

  getUsersUnitsRequest,
  getUsersUnitsSuccess,
  getUsersUnitsError,

  getUsersUnitsUsedRequest,
  getUsersUnitsUsedSuccess,
  getUsersUnitsUsedError,
} from './actions';


function* getUsersUnitsSaga({ params }) {
  try {
    yield put(getUsersUnitsRequest());

    const { data: { data: usersUnits } } = yield call(() => apiGetUsersUnits({
      search: params.search,
      isUsed: false,
      offset: 0,
      top: 1000,
    }));

    yield put(getUsersUnitsSuccess(usersUnits));
  } catch (error) {
    console.log('GET USERS UNITS ERROR', error);
    yield put(getUsersUnitsError(error));
  }
}

function* getUsersUnitsUsedSaga(data) {
  try {
    yield put(getUsersUnitsUsedRequest());

    const { data: { data: usersUnitsUsed } } = yield call(() => apiGetUsersUnits({
      isUsed: true,
      offset: 0,
      top: 1000,
    }));

    yield put(getUsersUnitsUsedSuccess(usersUnitsUsed));
  } catch (error) {
    console.log('GET USERS UNITS USED ERROR', error);
    yield put(getUsersUnitsUsedError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_USERS_UNITS, getUsersUnitsSaga);
  yield takeEvery(actionTypes.GET_USERS_UNITS_USED, getUsersUnitsUsedSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
