import { actionTypes } from './actions';

const initialState = {
  usersUnitsTypes: [],
  getUsersUnitsTypesRequest: false,
  getUsersUnitsTypesSuccess: false,
  getUsersUnitsTypesError: false,
};

const handleGetUsersUnitsTypesRequest = (state) => ({
  ...state,
  getUsersUnitsTypesRequest: true,
  getUsersUnitsTypesSuccess: false,
  getUsersUnitsTypesError: false,
});

const handleGetUsersUnitsTypesSuccess = (state, action) => ({
  ...state,
  getUsersUnitsTypesRequest: false,
  getUsersUnitsTypesSuccess: true,
  getUsersUnitsTypesError: false,
  usersUnitsTypes: action.data,
});

const handleGetUsersUnitsTypesError = (state) => ({
  ...state,
  getUsersUnitsTypesRequest: false,
  getUsersUnitsTypesSuccess: false,
  getUsersUnitsTypesError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE]: handleResetState,

    [actionTypes.GET_USERS_UNITS_TYPES_REQUEST]: handleGetUsersUnitsTypesRequest,
    [actionTypes.GET_USERS_UNITS_TYPES_SUCCESS]: handleGetUsersUnitsTypesSuccess,
    [actionTypes.GET_USERS_UNITS_TYPES_ERROR]: handleGetUsersUnitsTypesError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
