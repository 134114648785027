import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetCurrentUser,
} from './api';

import {
  actionTypes,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
} from './actions';


function* getCurrentUserSaga() {
  try {
    yield put(getCurrentUserRequest());

    const { data: { data: user } } = yield call(() => apiGetCurrentUser());

    let _user = user;
    // let _user = {
    //   globalId: '1',
    //   // "email": "testgspf@pik.ru",
    //   email: 'chernovakiu@pik.ru',
    //   name: 'Йован',
    //   surname: 'Аккерман',
    //   middleName: 'Тестович',
    //   positionId: null,
    //   positionName: 'Инженер',
    //   companyId: null,
    //   companyName: null,
    //   unitId: '00a22e60-3c30-11e9-a5c8-0050568db905',
    //   unitName: null,
    //   employmentStartDate: '2015-04-14 00:00:00',
    //   employmentEndDate: null,
    //   fullName: null,
    //   isSuperAdmin: true,
    // };

    // In case we use test login and apiGetCurrentUser returns {data: null}
    // for user data
    if (!_user) {
      _user = {
        globalId: '1',
        // "email": "testgspf@pik.ru",
        email: 'chernovakiu@pik.ru',
        name: 'Йован',
        surname: 'Аккерман',
        middleName: 'Тестович',
        positionId: null,
        positionName: 'Инженер',
        companyId: null,
        companyName: null,
        unitId: '00a22e60-3c30-11e9-a5c8-0050568db905',
        unitName: null,
        employmentStartDate: '2015-04-14 00:00:00',
        employmentEndDate: null,
        fullName: null,
        isSuperAdmin: true,
      };
    }

    yield put(getCurrentUserSuccess({
      user: _user,
    }));
  } catch (error) {
    console.log('GET CURRENT USER ERROR', error && error.response && error.response.data);

    yield put(getCurrentUserError());
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_CURRENT_USER, getCurrentUserSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
