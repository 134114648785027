export const actionTypes = {
  CREATE_NEWS_ARTICLE: 'CREATE_NEWS_ARTICLE',
  UPDATE_NEWS_ARTICLE: 'UPDATE_NEWS_ARTICLE',
  UNPUBLISH_NEWS_ARTICLE: 'UNPUBLISH_NEWS_ARTICLE',

  GET_NEWS_ARTICLE: 'GET_NEWS_ARTICLE',
  GET_NEWS_ARTICLE_REQUEST: 'GET_NEWS_ARTICLE_REQUEST',
  GET_NEWS_ARTICLE_SUCCESS: 'GET_NEWS_ARTICLE_SUCCESS',
  GET_NEWS_ARTICLE_ERROR: 'GET_NEWS_ARTICLE_ERROR',

  UNPUBLISH_NEWS_ARTICLE_SUCCESS: 'UNPUBLISH_NEWS_ARTICLE_SUCCESS',

  SAVE_NEWS_ARTICLE_REQUEST: 'SAVE_NEWS_ARTICLE_REQUEST',
  SAVE_NEWS_ARTICLE_SUCCESS: 'SAVE_NEWS_ARTICLE_SUCCESS',
  SAVE_NEWS_ARTICLE_ERROR: 'SAVE_NEWS_ARTICLE_ERROR',

  SAVE_AND_PUBLISH_NEWS_ARTICLE_REQUEST: 'SAVE_AND_PUBLISH_NEWS_ARTICLE_REQUEST',
  SAVE_AND_PUBLISH_NEWS_ARTICLE_SUCCESS: 'SAVE_AND_PUBLISH_NEWS_ARTICLE_SUCCESS',
  SAVE_AND_PUBLISH_NEWS_ARTICLE_ERROR: 'SAVE_AND_PUBLISH_NEWS_ARTICLE_ERROR',

  DELETE_NEWS_ARTICLE: 'DELETE_NEWS_ARTICLE',
  DELETE_NEWS_ARTICLE_REQUEST: 'DELETE_NEWS_ARTICLE_REQUEST',
  DELETE_NEWS_ARTICLE_SUCCESS: 'DELETE_NEWS_ARTICLE_SUCCESS',

  RESET_STATE_NEWS_ARTICLE_EDITOR: 'RESET_STATE_NEWS_ARTICLE_EDITOR',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_ARTICLE_EDITOR });

export const createNewsArticle = (params) => ({ type: actionTypes.CREATE_NEWS_ARTICLE, params });

export const updateNewsArticle = (params, callback) => ({ type: actionTypes.UPDATE_NEWS_ARTICLE, params, callback });

export const unpublishNewsArticle = (newsArticleId) => ({ type: actionTypes.UNPUBLISH_NEWS_ARTICLE, newsArticleId });

export const getNewsArticle = (id) => ({ type: actionTypes.GET_NEWS_ARTICLE, id });

export const getNewsArticleRequest = () => ({ type: actionTypes.GET_NEWS_ARTICLE_REQUEST });

export const getNewsArticleSuccess = (newsArticle) => ({ type: actionTypes.GET_NEWS_ARTICLE_SUCCESS, newsArticle });

export const getNewsArticleError = (error) => ({ type: actionTypes.GET_NEWS_ARTICLE_ERROR, error });

export const deleteNewsArticle = (newsId) => ({ type: actionTypes.DELETE_NEWS_ARTICLE, newsId });

export const unpublishNewsArticleSuccess = () => ({ type: actionTypes.UNPUBLISH_NEWS_ARTICLE_SUCCESS });

export const saveNewsArticleRequest = () => ({ type: actionTypes.SAVE_NEWS_ARTICLE_REQUEST });
export const saveNewsArticleSuccess = (newsArticle) => ({ type: actionTypes.SAVE_NEWS_ARTICLE_SUCCESS, newsArticle });
export const saveNewsArticleError = () => ({ type: actionTypes.SAVE_NEWS_ARTICLE_ERROR });

export const saveAndPublishNewsArticleRequest = () => ({ type: actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_REQUEST });
export const saveAndPublishNewsArticleSuccess = (newsArticle) => ({ type: actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_SUCCESS, newsArticle });
export const saveAndPublishNewsArticleError = () => ({ type: actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_ERROR });

export const deleteNewsArticleRequest = () => ({ type: actionTypes.DELETE_NEWS_ARTICLE_REQUEST });
export const deleteNewsArticleSuccess = () => ({ type: actionTypes.DELETE_NEWS_ARTICLE_SUCCESS });
