const baseAPIUrlProxy = process.env.API_URL_PROXY;
const baseAPIUrlAuth = process.env.API_URL_AUTH;
const uploadBaseAPIUrl = process.env.UPLOAD_API_URL;
const baseAPIUrlEmail = "/email";

export default {
  authApi: {
    login: `${baseAPIUrlAuth}/login`,
    check: `${baseAPIUrlAuth}/check`,
  },
  clientApi: {
    email: {
      support: `${baseAPIUrlEmail}/support`,
    },
    upload: {
      image: `${uploadBaseAPIUrl}/api/upload/image`,
      file: `${uploadBaseAPIUrl}/api/upload/file`,
    },
    ai: {
      getLoginUrl: `${baseAPIUrlProxy}/ai/generate-login-url`,
      notifyOnNewUser: `${baseAPIUrlProxy}/ai/notify-on-new-user`,
    },
    users: {
      list: `${baseAPIUrlProxy}/users`,
      item: (globalId = ":globalId") => `${baseAPIUrlProxy}/users/${globalId}`,
      current: `${baseAPIUrlProxy}/users/current`,
      checkCurrentUserIsAdmin: `${baseAPIUrlProxy}/users/current/check-is-admin`,
      checkCurrentUserIsBlocked: `${baseAPIUrlProxy}/users/current/check-is-blocked`,
      adminsList: `${baseAPIUrlProxy}/users/admins`,
      update: `${baseAPIUrlProxy}/users`,
      setIsStartedUsingAI: `${baseAPIUrlProxy}/users/set-is-started-using-ai`,
      activityCounter: `${baseAPIUrlProxy}/users/newUsersActivityCounter/list`,
      listStartedUsingAI: `${baseAPIUrlProxy}/users/started-using-ai`,
    },
    usersUnits: {
      list: `${baseAPIUrlProxy}/users-units`,
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/users-units/${usersUnitId}`,
    },
    usersUnitsTypes: {
      list: `${baseAPIUrlProxy}/users-units-types`,
    },
    news: {
      list: `${baseAPIUrlProxy}/news`,
      item: (id = ":id") => `${baseAPIUrlProxy}/news/${id}`,
      like: `${baseAPIUrlProxy}/news/like`,
      dislike: `${baseAPIUrlProxy}/news/dislike`,
      create: `${baseAPIUrlProxy}/news`,
      update: `${baseAPIUrlProxy}/news`,
      delete: `${baseAPIUrlProxy}/news`,
    },
    projectsNews: {
      list: `${baseAPIUrlProxy}/projects-news`,
      item: (id = ":id") => `${baseAPIUrlProxy}/projects-news/${id}`,
      like: `${baseAPIUrlProxy}/projects-news/like`,
      dislike: `${baseAPIUrlProxy}/projects-news/dislike`,
      create: `${baseAPIUrlProxy}/projects-news`,
      update: `${baseAPIUrlProxy}/projects-news`,
      delete: `${baseAPIUrlProxy}/projects-news`,
    },
    projectsNewsSearch: {
      search: `${baseAPIUrlProxy}/projects-news-search/search`,
      autocomplete: `${baseAPIUrlProxy}/projects-news-search/autocomplete`,
    },
    projectsNewsCategories: {
      list: `${baseAPIUrlProxy}/projects-news-categories`,
      item: (id = ":id") => `${baseAPIUrlProxy}/projects-news-categories/${id}`,
    },
    docs: {
      list: `${baseAPIUrlProxy}/docs`,
      listCulture: `${baseAPIUrlProxy}/docs/culture`,
      listTeam: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}`,
      listTeamFolder: (
        usersUnitId = ":usersUnitId",
        docsFolderId = ":docsFolderId"
      ) =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}/docs-folder/${docsFolderId}`,
      listTeamWithoutFolder: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}/without-folder`,
      item: (id = ":id") => `${baseAPIUrlProxy}/docs/${id}`,
      create: `${baseAPIUrlProxy}/docs`,
      update: `${baseAPIUrlProxy}/docs`,
      delete: `${baseAPIUrlProxy}/docs`,
      resetReads: `${baseAPIUrlProxy}/docs/reset-reads`,
      detachDocsCommonProcess: `${baseAPIUrlProxy}/docs/detach-docs-common-process`,
      attachDocsCommonProcess: `${baseAPIUrlProxy}/docs/attach-docs-common-process`,
      copyes: (docId = ":docId") => `${baseAPIUrlProxy}/docs/copyes/${docId}`,
      textReplace: `${baseAPIUrlProxy}/docs/text-replace`,
      deleteLink: `${baseAPIUrlProxy}/docs/delete-link`,
      checkLinks: `${baseAPIUrlProxy}/docs/check-links`,
      checkDocsForSearchUnpublished: `${baseAPIUrlProxy}/docs/check-docs-for-search-unpublished`,
    },
    docsCopies: {
      markBlocks: `${baseAPIUrlProxy}/docs-copies/mark-blocks`,
    },
    docsSearch: {
      search: `${baseAPIUrlProxy}/docs-search/search`,
      autocomplete: `${baseAPIUrlProxy}/docs-search/autocomplete`,
    },
    docsChangelogs: {
      create: `${baseAPIUrlProxy}/docs-changelogs`,
      update: `${baseAPIUrlProxy}/docs-changelogs`,
      delete: `${baseAPIUrlProxy}/docs-changelogs`,
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs-changelogs/${usersUnitId}`,
    },
    docsTeamRead: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docsTeamRead/${usersUnitId}`,
      post: `${baseAPIUrlProxy}/docsTeamRead`,
      all: `${baseAPIUrlProxy}/docsTeamRead/all`,
    },
    docsCommonProcesses: {
      item: (id = ":id") => `${baseAPIUrlProxy}/docs-common-processes/${id}`,
      create: `${baseAPIUrlProxy}/docs-common-processes`,
      update: `${baseAPIUrlProxy}/docs-common-processes`,
      delete: `${baseAPIUrlProxy}/docs-common-processes`,
    },
    docsFolders: {
      list: `${baseAPIUrlProxy}/docs-folders`,
      item: (id = ":id") => `${baseAPIUrlProxy}/docs-folders/${id}`,
      create: `${baseAPIUrlProxy}/docs-folders`,
      update: `${baseAPIUrlProxy}/docs-folders`,
      delete: `${baseAPIUrlProxy}/docs-folders`,
    },
    iSpring: {
      users: `${baseAPIUrlProxy}/ispring/user`,
      courses: `${baseAPIUrlProxy}/ispring/content`,
      courseModules: `${baseAPIUrlProxy}/ispring/learners/modules/results`,
      userResults: `${baseAPIUrlProxy}/ispring/learners/results`,
    },
    stories: {
      list: `${baseAPIUrlProxy}/stories`,
      create: `${baseAPIUrlProxy}/stories`,
      delete: `${baseAPIUrlProxy}/stories`,
      update: `${baseAPIUrlProxy}/stories`,
    },
    champions: {
      list: `${baseAPIUrlProxy}/champions`,
      item: (id = ":id") => `${baseAPIUrlProxy}/champions/${id}`,
      create: `${baseAPIUrlProxy}/champions`,
      delete: `${baseAPIUrlProxy}/champions`,
      update: `${baseAPIUrlProxy}/champions`,
    },
    championsList: {
      list: `${baseAPIUrlProxy}/champions-list`,
      create: `${baseAPIUrlProxy}/champions-list`,
      delete: `${baseAPIUrlProxy}/champions-list`,
      update: `${baseAPIUrlProxy}/champions-list`,
    },
    championsListLink: {
      item: `${baseAPIUrlProxy}/champions-list-link`,
      create: `${baseAPIUrlProxy}/champions-list-link`,
      update: `${baseAPIUrlProxy}/champions-list-link`,
    },
    foldersCourses: {
      list: `${baseAPIUrlProxy}/folders-courses`,
      create: `${baseAPIUrlProxy}/folders-courses`,
      delete: `${baseAPIUrlProxy}/folders-courses`,
      update: `${baseAPIUrlProxy}/folders-courses`,
    },
    foldersCoursesHistory: {
      list: `${baseAPIUrlProxy}/folders-courses-history`,
      update: `${baseAPIUrlProxy}/folders-courses-history`,
    },
    folderQueue: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/folder-queue/${usersUnitId}`,
      create: `${baseAPIUrlProxy}/folder-queue`,
      delete: `${baseAPIUrlProxy}/folder-queue`,
      update: `${baseAPIUrlProxy}/folder-queue`,
    },
    wordsSpellingList: {
      list: `${baseAPIUrlProxy}/speller`,
      post: `${baseAPIUrlProxy}/speller`,
      delete: `${baseAPIUrlProxy}/speller`,
    },
    hidingDateDocs: {
      list: `${baseAPIUrlProxy}/hidingDateDocs`,
      post: `${baseAPIUrlProxy}/hidingDateDocs`,
      delete: `${baseAPIUrlProxy}/hidingDateDocs`,
    },
    docsVerifyReminder: {
      list: `${baseAPIUrlProxy}/docsVerifyReminder`,
      post: `${baseAPIUrlProxy}/docsVerifyReminder`,
      update: `${baseAPIUrlProxy}/docsVerifyReminder`,
      delete: `${baseAPIUrlProxy}/docsVerifyReminder`,
      listUsers: `${baseAPIUrlProxy}/docsVerifyReminder/users`,
      replace: `${baseAPIUrlProxy}/docsVerifyReminder/replace`,
    },
    docsQueue: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs-queue/${usersUnitId}`,
      create: `${baseAPIUrlProxy}/docs-queue`,
      update: `${baseAPIUrlProxy}/docs-queue`,
    },
    webinars: {
      list: `${baseAPIUrlProxy}/webinars`,
    },
    docsHistoryChanges: {
      list: `${baseAPIUrlProxy}/docs-history-changes`,
      post: `${baseAPIUrlProxy}/docs-history-changes`,
    },
    teams: {
      list: `${baseAPIUrlProxy}/teams`,
      post: `${baseAPIUrlProxy}/teams`,
      put: `${baseAPIUrlProxy}/teams`,
      delete: `${baseAPIUrlProxy}/teams`,
    },
    usefulProfile: {
      list: `${baseAPIUrlProxy}/useful-profile`,
      item: (id = ":id") => `${baseAPIUrlProxy}/useful-profile/${id}`,
      post: `${baseAPIUrlProxy}/useful-profile`,
      put: `${baseAPIUrlProxy}/useful-profile`,
      delete: `${baseAPIUrlProxy}/useful-profile`,
    },
    usefulStandarts: {
      list: `${baseAPIUrlProxy}/useful-standarts`,
      post: `${baseAPIUrlProxy}/useful-standarts`,
      delete: `${baseAPIUrlProxy}/useful-standarts`,
    },
    usefulAvatars: {
      list: `${baseAPIUrlProxy}/useful-avatars`,
      post: `${baseAPIUrlProxy}/useful-avatars`,
      put: `${baseAPIUrlProxy}/useful-avatars`,
      delete: `${baseAPIUrlProxy}/useful-avatars`,
    },
    learningMap: {
      list: `${baseAPIUrlProxy}/learning-map`,
      post: `${baseAPIUrlProxy}/learning-map`,
      put: `${baseAPIUrlProxy}/learning-map`,
      delete: `${baseAPIUrlProxy}/learning-map`,
    },
    usefulVoice: {
      list: `${baseAPIUrlProxy}/useful-voice`,
      post: `${baseAPIUrlProxy}/useful-voice`,
      put: `${baseAPIUrlProxy}/useful-voice`,
      delete: `${baseAPIUrlProxy}/useful-voice`,
      pagesOrder: `${baseAPIUrlProxy}/useful-voice/pages-order`,
    },
    unload: {
      docsScripts: `${baseAPIUrlProxy}/unload/docs/scripts`,
      projectsScripts: `${baseAPIUrlProxy}/unload/projects/scripts`,
    }
  },
};
