import { actionTypes } from './actions';

const initialState = {
  doc: null,
  getDocRequest: false,
  getDocSuccess: false,
  getDocError: false,
  saveDocRequest: false,
  saveAndPublishDocRequest: false,
  deleteDocRequest: false,
};

const handleGetDocRequest = (state) => ({
  ...state,
  getDocRequest: true,
  getDocSuccess: false,
  getDocError: false,
});

const handleGetDocSuccess = (state, action) => ({
  ...state,
  getDocRequest: false,
  getDocSuccess: true,
  getDocError: false,
  doc: action.data,
});

const handleGetDocError = (state) => ({
  ...state,
  getDocRequest: false,
  getDocSuccess: false,
  getDocError: true,
});

const handleSaveDocRequest = (state) => ({
  ...state,
  saveDocRequest: true,
});

const handleSaveDocSuccess = (state) => ({
  ...state,
  saveDocRequest: false,
});

const handleSaveDocError = (state) => ({
  ...state,
  saveDocRequest: false,
});

const handleSaveAndPublishDocRequest = (state) => ({
  ...state,
  saveAndPublishDocRequest: true,
});

const handleSaveAndPublishDocSuccess = (state, action) => ({
  ...state,
  saveAndPublishDocRequest: false,
  doc: {
    ...state.doc,
    isPublished: action.data.isPublished,
  },
});

const handleSaveAndPublishDocError = (state, action) => ({
  ...state,
  saveAndPublishDocRequest: false,
});

const handleDeleteDocRequest = (state) => ({
  ...state,
  deleteDocRequest: true,
});

const handleDeleteDocSuccess = (state) => ({
  ...state,
  deleteDocRequest: false,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOC_EDITOR]: handleResetState,

    [actionTypes.GET_DOC_REQUEST]: handleGetDocRequest,
    [actionTypes.GET_DOC_SUCCESS]: handleGetDocSuccess,
    [actionTypes.GET_DOC_ERROR]: handleGetDocError,

    [actionTypes.SAVE_DOC_REQUEST]: handleSaveDocRequest,
    [actionTypes.SAVE_DOC_SUCCESS]: handleSaveDocSuccess,
    [actionTypes.SAVE_DOC_ERROR]: handleSaveDocError,

    [actionTypes.SAVE_AND_PUBLISH_DOC_REQUEST]: handleSaveAndPublishDocRequest,
    [actionTypes.SAVE_AND_PUBLISH_DOC_SUCCESS]: handleSaveAndPublishDocSuccess,
    [actionTypes.SAVE_AND_PUBLISH_DOC_ERROR]: handleSaveAndPublishDocError,

    [actionTypes.DELETE_DOC_REQUEST]: handleDeleteDocRequest,
    [actionTypes.DELETE_DOC_SUCCESS]: handleDeleteDocSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
