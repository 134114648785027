const initialState = {
  selectedText: "",
  contentState: null,
};

function editorReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SELECTED_TEXT":
      return { ...state, selectedText: action.payload };
    case "SET_CONTENT_STATE":
      return { ...state, contentState: action.payload };
    default:
      return state;
  }
}

export default editorReducer;
