export const actionTypes = {
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL: 'CLOSE_CONFIRM_MODAL',
};

export const open = () => ({
  type: actionTypes.OPEN_CONFIRM_MODAL,
});

export const close = () => ({
  type: actionTypes.CLOSE_CONFIRM_MODAL,
});
