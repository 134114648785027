export const actionTypes = {
  GET_LOGIN_URL: 'GET_LOGIN_URL',
  GET_LOGIN_URL_REQUEST: 'GET_LOGIN_URL_REQUEST',
  GET_LOGIN_URL_SUCCESS: 'GET_LOGIN_URL_SUCCESS',
};

export const getLoginUrl = (data) => ({ type: actionTypes.GET_LOGIN_URL, data });

export const getLoginUrlRequest = () => ({ type: actionTypes.GET_LOGIN_URL_REQUEST });

export const getLoginUrlSuccess = (data) => ({ type: actionTypes.GET_LOGIN_URL_SUCCESS, data });
