// eslint-disable-next-line consistent-return
const checkUpdated = (docBlock, articleBlock) => {
  if (docBlock.type === "text") {
    return docBlock.textHtml !== articleBlock.textHtml;
  }
  if (docBlock.type === "image") {
    return docBlock.image !== articleBlock.image;
  }
  if (docBlock.type === "gallery") {
    let updated = false;

    if (articleBlock.galleryItems) {
      if (docBlock.galleryItems.length !== articleBlock.galleryItems.length) {
        return true;
      }

      for (let i = 0; i < docBlock.galleryItems.length; i += 1) {
        if (
          docBlock.galleryItems[i].image !== articleBlock.galleryItems[i].image
        ) {
          updated = true;
          break;
        }
      }
    }

    return updated;
  }
  if (docBlock.type === "player") {
    return docBlock.player !== articleBlock.player;
  }
  if (docBlock.type === "video") {
    return docBlock.video !== articleBlock.video;
  }
  if (docBlock.type === "tableCustom") {
    const updated = false;

    if (docBlock.table.length !== articleBlock.table.length) {
      return true;
    }

    // for (let i = 0; i < docBlock.table.length; i += 1) {
    //   if (
    //     docBlock.table[i].titleHtml !== articleBlock.table[i].titleHtml
    //     || docBlock.table[i].height !== articleBlock.table[i].height
    //   ) {
    //     updated = true;
    //     break;
    //   }

    //   for (let k = 0; k < docBlock.table[i].content.length; k += 1) {
    //     if (
    //       docBlock.table[i].content[k].height
    //         !== articleBlock.table[i].content[k].height
    //       || docBlock.table[i].content[k].textHTML
    //         !== articleBlock.table[i].content[k].textHTML
    //     ) {
    //       updated = true;
    //       break;
    //     }
    //   }
    // }

    return updated;
  }
};

export default checkUpdated;
