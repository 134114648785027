import { actionTypes } from './actions';

const initialState = {
  loginUrl: null,
  loginUrlRequest: false,
};

const handleGetLoginUrlRequest = state => ({
  ...state,
  loginUrlRequest: true,
});

const handleGetLoginUrlSuccess = (state, action) => ({
  ...state,
  loginUrl: action.data.loginUrl,
  loginUrlRequest: false,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_LOGIN_URL_REQUEST]: handleGetLoginUrlRequest,
    [actionTypes.GET_LOGIN_URL_SUCCESS]: handleGetLoginUrlSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
