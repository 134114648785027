import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetCultureDocs = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.listCulture,
  params,
});

export const apiUpdateDoc = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.update,
  data,
});
