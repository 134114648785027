import { actionTypes } from './actions';

const initialState = {
  projectsNews: [],
  searchProjectsNewsRequest: false,
  searchProjectsNewsSuccess: false,
  searchProjectsNewsError: false,
};

const handleSearchProjectsNewsRequest = (state) => ({
  ...state,
  searchProjectsNewsRequest: true,
  searchProjectsNewsSuccess: false,
  searchProjectsNewsError: false,
});

const handleSearchProjectsNewsSuccess = (state, action) => ({
  ...state,
  searchProjectsNewsRequest: false,
  searchProjectsNewsSuccess: true,
  searchProjectsNewsError: false,
  projectsNews: action.data,
});

const handleSearchProjectsNewsError = (state) => ({
  ...state,
  searchProjectsNewsRequest: false,
  searchProjectsNewsSuccess: false,
  searchProjectsNewsError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_SEARCH_PROJECTS_NEWS]: handleResetState,

    [actionTypes.SEARCH_PROJECTS_NEWS_REQUEST]: handleSearchProjectsNewsRequest,
    [actionTypes.SEARCH_PROJECTS_NEWS_SUCCESS]: handleSearchProjectsNewsSuccess,
    [actionTypes.SEARCH_PROJECTS_NEWS_ERROR]: handleSearchProjectsNewsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
