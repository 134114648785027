export default {
  mail: (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="10" height="10" rx="1.5" stroke="black" />
      <path
        d="M1 1L4.17127 3.81891C4.92904 4.49248 6.07095 4.49248 6.82873 3.81891L10 1"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  ),
  telegram: (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0L0 4.875L3.19581 5.9008L8.12502 1.89584L4.3757 6.27955L4.37875 6.28054L4.37501 6.27955V8.66667L6.16721 6.85458L8.43752 7.58334L10 0Z"
        fill="black"
      />
    </svg>
  ),
};
