import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiSendSupportEmail,
} from './api';

import {
  actionTypes,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailError,
} from './actions';

function* sendEmailSaga({ data }) {
  try {
    const loading = yield select((state) => state.errorPopupService.sendEmailRequest);

    if (loading) {
      return;
    }

    yield put(sendEmailRequest());

    const response = yield call(() => apiSendSupportEmail({
      ...data,
    }));

    if (response.data && response.data.success) {
      yield put(sendEmailSuccess());
    } else {
      yield put(sendEmailError('Ошибка отправки письма'));
    }
  } catch (error) {
    yield put(sendEmailError('Ошибка отправки письма'));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.SEND_EMAIL_ERROR_POPUP, sendEmailSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
