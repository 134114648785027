import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiCreateNewsArticle = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.projectsNews.create,
  data,
});

export const apiUpdateNewsArticle = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.projectsNews.update,
  data,
});

export const apiGetNewsArticle = (id) => axios({
  method: 'GET',
  url: apiUrls.clientApi.projectsNews.item(id),
});

export const apiDeleteNewsArticle = (newsId) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.projectsNews.delete,
  params: {
    newsId,
  },
});
