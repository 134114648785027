import { actionTypes } from './actions';

const initialState = {
  getUserResultsRequest: false,
  getUserResultsSuccess: false,
  getUserResultsError: false,

  userResults: [],
};


const handleGetUserResultsRequest = (state) => ({
  ...state,
  getUserResultsRequest: true,
  getUserResultsSuccess: false,
  getUserResultsError: false,
});

const handleGetUserResultsSuccess = (state, action) => ({
  ...state,
  getUserResultsRequest: false,
  getUserResultsSuccess: true,
  getUserResultsError: false,
  userResults: action.userResults,
});

const handleGetUserResultsError = (state) => ({
  ...state,
  getUserResultsRequest: false,
  getUserResultsSuccess: false,
  getUserResultsError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_ISPRING_USER_RESULTS]: handleResetState,

    [actionTypes.GET_ISPRING_USER_RESULTS_REQUEST]: handleGetUserResultsRequest,
    [actionTypes.GET_ISPRING_USER_RESULTS_SUCCESS]: handleGetUserResultsSuccess,
    [actionTypes.GET_ISPRING_USER_RESULTS_ERROR]: handleGetUserResultsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
