import { actionTypes } from './actions';

const initialState = {
  projectsNewsCategory: null,

  getProjectsNewsCategoryRequest: false,
  getProjectsNewsCategorySuccess: false,
  getProjectsNewsCategoryError: false,
};

const handleGetProjectsNewsCategoryRequest = (state) => ({
  ...state,
  getProjectsNewsCategoryRequest: true,
  getProjectsNewsCategorySuccess: false,
  getProjectsNewsCategoryError: false,
});

const handleGetProjectsNewsCategorySuccess = (state, action) => ({
  ...state,
  getProjectsNewsCategoryRequest: false,
  getProjectsNewsCategorySuccess: true,
  getProjectsNewsCategoryError: false,
  projectsNewsCategory: action.data,
});

const handleGetProjectsNewsCategoryError = (state) => ({
  ...state,
  getProjectsNewsCategoryRequest: false,
  getProjectsNewsCategorySuccess: false,
  getProjectsNewsCategoryError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_PROJECTS_NEWS_CATEGORY]: handleResetState,

    [actionTypes.GET_PROJECTS_NEWS_CATEGORY_REQUEST]: handleGetProjectsNewsCategoryRequest,
    [actionTypes.GET_PROJECTS_NEWS_CATEGORY_SUCCESS]: handleGetProjectsNewsCategorySuccess,
    [actionTypes.GET_PROJECTS_NEWS_CATEGORY_ERROR]: handleGetProjectsNewsCategoryError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
