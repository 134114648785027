export const actionTypes = {
  GET_PROJECTS_NEWS_CATEGORIES: 'GET_PROJECTS_NEWS_CATEGORIES',
  GET_PROJECTS_NEWS_CATEGORIES_REQUEST: 'GET_PROJECTS_NEWS_CATEGORIES_REQUEST',
  GET_PROJECTS_NEWS_CATEGORIES_SUCCESS: 'GET_PROJECTS_NEWS_CATEGORIES_SUCCESS',
  GET_PROJECTS_NEWS_CATEGORIES_ERROR: 'GET_PROJECTS_NEWS_CATEGORIES_ERROR',

  RESET_STATE_PROJECTS_NEWS_CATEGORIES: 'RESET_STATE_PROJECTS_NEWS_CATEGORIES',
};

export const resetStateProjectsNewsCategories = () => ({ type: actionTypes.RESET_STATE_PROJECTS_NEWS_CATEGORIES });

export const getProjectsNewsCategories = () => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORIES });

export const getProjectsNewsCategoriesRequest = () => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORIES_REQUEST });

export const getProjectsNewsCategoriesSuccess = (data) => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORIES_SUCCESS, data });

export const getProjectsNewsCategoriesError = (error) => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORIES_ERROR, error });
