import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetDocsCommonProcess, 
  apiCreateDocsCommonProcess,
  apiDetachDocsCommonProcess,
  apiUpdateDocsCommonProcess,
  apiDeleteDocsCommonProcess,
  apiUpdateDoc,
} from './api';

import {
  actionTypes,
  getDocsCommonProcessRequest,
  getDocsCommonProcessSuccess,
  createDocsCommonProcessRequest,
  createDocsCommonProcessSuccess,
  deleteDocsCommonProcessRequest,
  deleteDocsCommonProcessSuccess,
  resetStateDocsCommonProcess,
} from './actions';

function* getDocsCommonProcessSaga(action) {
  try {
    const { 
      params: {
        docsCommonProcessId
      }
    } = action

    yield put(getDocsCommonProcessRequest());

    const {
      data: { data: docsCommonProcess },
    } = yield call(() => apiGetDocsCommonProcess(docsCommonProcessId));

    yield put(getDocsCommonProcessSuccess(docsCommonProcess));
  } catch (error) {
    console.log('GET DOCS COMMON PROCESS ERROR', error);
  }
}

function* deleteDocsCommonProcessSaga(action) {
  try {
    const { 
      params: {
        docsCommonProcessId
      }
    } = action

    yield put(deleteDocsCommonProcessRequest());

    yield call(() => apiDetachDocsCommonProcess(docsCommonProcessId));

    // Remove rows from docs_docsCommonProcess table
    yield call(() => apiUpdateDocsCommonProcess({ 
      id: docsCommonProcessId,
      items: []
    }));

    yield call(() => apiDeleteDocsCommonProcess(docsCommonProcessId));

    yield put(deleteDocsCommonProcessSuccess());

    yield put(resetStateDocsCommonProcess());

    if (action.params.successCallback) {
      action.params.successCallback();
    }
  } catch (error) {
    console.log('DELETE DOCS COMMON PROCESS ERROR', error);
  }
}

function* createDocsCommonProcessSaga(action) {
  try {
    const { 
      params: {
        docId
      }
    } = action

    yield put(createDocsCommonProcessRequest());

    const {
      data: { data: docsCommonProcessCreated },
    } = yield call(() => apiCreateDocsCommonProcess({ 
      items: [
        {
          docId: docId,
          order: 0
        }
      ] 
    }));

    yield call(() => apiUpdateDoc({ 
      id: docId,
      docsCommonProcessId: docsCommonProcessCreated.id
    }));

    const {
      data: { data: docsCommonProcess },
    } = yield call(() => apiGetDocsCommonProcess(docsCommonProcessCreated.id));

    yield put(createDocsCommonProcessSuccess(docsCommonProcess));

    if (action.params.successCallback) {
      action.params.successCallback({
        docsCommonProcess
      });
    }
  } catch (error) {
    console.log('CREATE DOCS COMMON PROCESS ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOCS_COMMON_PROCESS, getDocsCommonProcessSaga);
  yield takeEvery(actionTypes.CREATE_DOCS_COMMON_PROCESS, createDocsCommonProcessSaga);
  yield takeEvery(actionTypes.DELETE_DOCS_COMMON_PROCESS, deleteDocsCommonProcessSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
