import axios from 'axios';

import apiServices from '../../common/apiServices';

export const apiGetCurrentUser = (params) => axios({
  method: 'GET',
  url: apiServices.clientApi.user.current,
  headers: {
    Authorization: 'Bearer ' + params.token,
  },
});

export const apiGetUsers = (params) => axios({
  method: 'GET',
  url: apiServices.clientApi.users.list,
  params,
});

export const apiGetUser = (id) => axios({
  method: 'GET',
  url: apiServices.clientApi.users.item(id),
});

export const apiUpdateUser = (data) => axios({
  method: 'PUT',
  url: apiServices.clientApi.users.update,
  data,
});
