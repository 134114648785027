import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiCreateDocsFolder = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.docsFolders.create,
  data,
});

export const apiUpdateDocsFolder = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docsFolders.update,
  data,
});

export const apiGetDocsFolder = (id) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docsFolders.item(id),
});

export const apiDeleteDocsFolder = (id) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.docsFolders.delete,
  params: {
    id,
  },
});
