import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetTeam = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.usersUnits.item(params.usersUnitId),
});

export const apiGetTeamDocs = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.listTeam(params.usersUnitId),
  params: {
    isPublished: true,
  },
});
