import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import { apiGetDocsFolders } from './api';

import {
  actionTypes,
  getDocsFoldersRequest,
  getDocsFoldersSuccess,
  getDocsFoldersError,
} from './actions';

function* getDocsFoldersSaga(data) {
  try {
    yield put(getDocsFoldersRequest());

    const {
      data: { data: docsFolders },
    } = yield call(() => apiGetDocsFolders({ usersUnitId: data.usersUnitId }));
    yield put(getDocsFoldersSuccess(docsFolders));
  } catch (error) {
    console.log('GET DOCS FOLDERS ERROR', error);
    yield put(getDocsFoldersError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOCS_FOLDERS, getDocsFoldersSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
