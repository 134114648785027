import convert from 'xml-js';

import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetCourses,
} from './api';

import {
  actionTypes,
  getCoursesRequest,
  getCoursesSuccess,
  getCoursesError,
} from './actions';


function* getCoursesSaga() {
  try {
    yield put(getCoursesRequest());

    const response = yield call(() => apiGetCourses());

    const xmlData = response.data;

    const convertedData = convert.xml2js(xmlData, { compact: true });

    const courses = convertedData.response.contentItem;

    yield put(getCoursesSuccess({
      courses,
    }));
  } catch (error) {
    console.log('GET_ISPRING_COURSES ERROR', error);
    yield put(getCoursesError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_ISPRING_COURSES, getCoursesSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
