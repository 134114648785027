import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  close as closeConfirmModal
} from '../confirmModal/actions';

import {
  apiGetNews,
  apiUpdateNewsArticle,
  apiDeleteNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsRequest,
  getNewsSuccess,
  getNewsError,

  getMoreNewsRequest,
  getMoreNewsSuccess,
  getMoreNewsError,

  getNewsUnpublishedRequest,
  getNewsUnpublishedSuccess,
  getNewsUnpublishedError,

  unpublishNewsArticleRequest,
  unpublishNewsArticleSuccess,

  deleteNewsArticleRequest,
  deleteNewsArticleSuccess,
} from './actions';


function* getNewsSaga(data) {
  try {
    yield put(getNewsRequest());
    const { offset, limit } = yield select(({ projectsNewsService }) => projectsNewsService);

    const { data: { data: news } } = yield call(() => apiGetNews({
      offset,
      limit: data.params.limit || limit,
      categoryId: data.params.categoryId,
    }));

    yield put(getNewsSuccess({
      news,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET NEWS ERROR', error);
    yield put(getNewsError(error));
  }
}

function* getMoreNewsSaga({ search, categoryId }) {
  try {
    yield put(getMoreNewsRequest());
    const { offset, limit } = yield select(({ projectsNewsService }) => projectsNewsService);
    const params = { offset, limit };

    if (search && search.length) {
      params.search = search;
    }

    if (categoryId) {
      params.categoryId = categoryId;
    }

    const { data: { data: news } } = yield call(() => apiGetNews(params));

    yield put(getMoreNewsSuccess({
      news,
      offset: offset + limit,
      isNoMoreNews: !news.length,
    }));
  } catch (error) {
    console.log('MORE NEWS ERROR', error);
    yield put(getMoreNewsError(error));
  }
}

function* getNewsUnpublishedSaga(data) {
  try {
    yield put(getNewsUnpublishedRequest());

    const { data: { data: news } } = yield call(() => apiGetNews({
      isPublished: false,
      categoryId: data.params.categoryId,
      orderBy: 'updatedAt',
    }));

    yield put(getNewsUnpublishedSuccess({
      news,
    }));
  } catch (error) {
    console.log('GET NEWS UNPUBLISHED ERROR', error);
    yield put(getNewsUnpublishedError(error));
  }
}

function* unpublishNewsArticleSaga({ newsId }) {
  try {
    yield put(unpublishNewsArticleRequest());

    yield call(() => apiUpdateNewsArticle({
      id: newsId,
      isPublished: false,
    }));

    yield put(unpublishNewsArticleSuccess(newsId));

    yield put(closeConfirmModal());

    store.addNotification({
      type: 'success',
      message: 'Проект снят с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('unpublishNewsArticleSaga ERROR', error);

    yield put(unpublishNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Проект не снят с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* deleteNewsArticleSaga({ newsId }) {
  try {
    yield put(deleteNewsArticleRequest());

    yield call(() => apiDeleteNewsArticle(newsId));

    yield put(deleteNewsArticleSuccess(newsId));

    yield put(closeConfirmModal());

    store.addNotification({
      type: 'success',
      message: 'Проект удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('deleteNewsArticleSaga ERROR', error);

    yield put(deleteNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Проект не удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS, getNewsSaga);
  yield takeEvery(actionTypes.GET_MORE_PROJECTS_NEWS, getMoreNewsSaga);
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS_UNPUBLISHED, getNewsUnpublishedSaga);
  yield takeEvery(actionTypes.UNPUBLISH_NEWS_ARTICLE_PROJECTS_NEWS, unpublishNewsArticleSaga);
  yield takeEvery(actionTypes.DELETE_NEWS_ARTICLE_PROJECTS_NEWS, deleteNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
