import { actionTypes } from './actions';

const initialState = {
  projectsNewsCategories: [],
  getProjectsNewsCategoriesRequest: false,
  getProjectsNewsCategoriesSuccess: false,
  getProjectsNewsCategoriesError: false,
};

const handleGetProjectsNewsCategoriesRequest = (state) => ({
  ...state,
  getProjectsNewsCategoriesRequest: true,
  getProjectsNewsCategoriesSuccess: false,
  getProjectsNewsCategoriesError: false,
});

const handleGetProjectsNewsCategoriesSuccess = (state, action) => ({
  ...state,
  getProjectsNewsCategoriesRequest: false,
  getProjectsNewsCategoriesSuccess: true,
  getProjectsNewsCategoriesError: false,
  projectsNewsCategories: action.data,
});

const handleGetProjectsNewsCategoriesError = (state) => ({
  ...state,
  getProjectsNewsCategoriesRequest: false,
  getProjectsNewsCategoriesSuccess: false,
  getProjectsNewsCategoriesError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_PROJECTS_NEWS_CATEGORIES]: handleResetState,

    [actionTypes.GET_PROJECTS_NEWS_CATEGORIES_REQUEST]: handleGetProjectsNewsCategoriesRequest,
    [actionTypes.GET_PROJECTS_NEWS_CATEGORIES_SUCCESS]: handleGetProjectsNewsCategoriesSuccess,
    [actionTypes.GET_PROJECTS_NEWS_CATEGORIES_ERROR]: handleGetProjectsNewsCategoriesError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
