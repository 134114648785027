import { actionTypes } from './actions';

const initialState = {
  isShow: false,
  sendEmailRequest: false,
  sendEmailSuccess: false,
  sendEmailError: false,
};

const handleShow = state => ({
  ...state,
  isShow: true,
  sendEmailRequest: false,
  sendEmailSuccess: false,
  sendEmailError: false,
});

const handleHide = state => ({
  ...state,
  isShow: false,
});

const handleSendEmailRequest = state => ({
  ...state,
  sendEmailRequest: true,
});

const handleSendEmailSuccess = state => ({
  ...state,
  sendEmailRequest: false,
  sendEmailSuccess: true,
});

const handleSendEmailError = (state, action) => ({
  ...state,
  sendEmailRequest: false,
  sendEmailError: action.error,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.SHOW_ERROR_POPUP]: handleShow,
    [actionTypes.HIDE_ERROR_POPUP]: handleHide,

    [actionTypes.SEND_EMAIL_REQUEST_ERROR_POPUP]: handleSendEmailRequest,
    [actionTypes.SEND_EMAIL_SUCCESS_ERROR_POPUP]: handleSendEmailSuccess,
    [actionTypes.SEND_EMAIL_ERROR_ERROR_POPUP]: handleSendEmailError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
