export const actionTypes = {
  GET_TEAM_2: 'GET_TEAM_2',
  GET_TEAM_2_REQUEST: 'GET_TEAM_2_REQUEST',
  GET_TEAM_2_SUCCESS: 'GET_TEAM_2_SUCCESS',
  GET_TEAM_2_ERROR: 'GET_TEAM_2_ERROR',

  RESET_STATE_TEAM_2: 'RESET_STATE_TEAM_2',

  GET_DOCS_FOLDER_2: 'GET_DOCS_FOLDER_2',
  GET_DOCS_FOLDER_REQUEST_2: 'GET_DOCS_FOLDER_REQUEST_2',
  GET_DOCS_FOLDER_SUCCESS_2: 'GET_DOCS_FOLDER_SUCCESS_2',
  GET_DOCS_FOLDER_ERROR_2: 'GET_DOCS_FOLDER_ERROR_2',

  RESET_STATE_DOCS_FOLDER_2: 'RESET_STATE_DOCS_FOLDER_2',

  GET_TEAM_FOLDER_DOCS: 'GET_TEAM_FOLDER_DOCS',
  GET_TEAM_FOLDER_DOCS_REQUEST: 'GET_TEAM_FOLDER_DOCS_REQUEST',
  GET_TEAM_FOLDER_DOCS_SUCCESS: 'GET_TEAM_FOLDER_DOCS_SUCCESS',
  GET_TEAM_FOLDER_DOCS_ERROR: 'GET_TEAM_FOLDER_DOCS_ERROR',

  RESET_STATE_TEAM_FOLDER_DOCS: 'RESET_STATE_TEAM_FOLDER_DOCS',
};

export const resetStateTeam = () => ({ type: actionTypes.RESET_STATE_TEAM_2 });

export const getTeam = (params) => ({ type: actionTypes.GET_TEAM_2, params });

export const getTeamRequest = () => ({ type: actionTypes.GET_TEAM_2_REQUEST });

export const getTeamSuccess = (params) => ({ type: actionTypes.GET_TEAM_2_SUCCESS, ...params });

export const getTeamError = (error) => ({ type: actionTypes.GET_TEAM_2_ERROR, error });


export const resetStateDocsFolder = () => ({ type: actionTypes.RESET_STATE_DOCS_FOLDER_2 });

export const getDocsFolder = (params) => ({ type: actionTypes.GET_DOCS_FOLDER_2, params });

export const getDocsFolderRequest = () => ({ type: actionTypes.GET_DOCS_FOLDER_REQUEST_2 });

export const getDocsFolderSuccess = (params) => ({ type: actionTypes.GET_DOCS_FOLDER_SUCCESS_2, ...params });

export const getDocsFolderError = (error) => ({ type: actionTypes.GET_DOCS_FOLDER_ERROR_2, error });


export const resetStateTeamFolderDocs = () => ({ type: actionTypes.RESET_STATE_TEAM_FOLDER_DOCS });

export const getTeamFolderDocs = (params) => ({ type: actionTypes.GET_TEAM_FOLDER_DOCS, params });

export const getTeamFolderDocsRequest = () => ({ type: actionTypes.GET_TEAM_FOLDER_DOCS_REQUEST });

export const getTeamFolderDocsSuccess = (params) => ({ type: actionTypes.GET_TEAM_FOLDER_DOCS_SUCCESS, ...params });

export const getTeamFolderDocsError = (error) => ({ type: actionTypes.GET_TEAM_FOLDER_DOCS_ERROR, error });
