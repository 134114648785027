import { actionTypes } from './actions';

const initialState = {
  getUserRequest: false,
  getUserSuccess: false,
  getUserError: false,

  getUsersRequest: false,
  getUsersSuccess: false,
  getUsersError: false,

  getMoreUsersRequest: false,
  getMoreUsersSuccess: false,
  getMoreUsersError: false,

  usersList: [],
  userData: null,

  limit: 50,
  offset: 0,
  isNoMoreUsers: false,

  updateUserRequest: false,
  updateUserSuccess: false,
  updateUserError: false,
};

const handleGetUserRequest = (state) => ({
  ...state,
  getUserRequest: true,
  getUserSuccess: false,
  getUserError: false,
});

const handleGetUserSuccess = (state, action) => ({
  ...state,
  getUserRequest: false,
  getUserSuccess: true,
  getUserError: false,
  userData: action.data,
});

const handleGetUserError = (state) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: false,
  getUsersError: true,
});


const handleGetUsersRequest = (state) => ({
  ...state,
  getUsersRequest: true,
  getUsersSuccess: false,
  getUsersError: false,
});

const handleGetUsersSuccess = (state, action) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: true,
  getUsersError: false,
  usersList: action.users,
  offset: action.offset,
  isNoMoreUsers: !action.users.length,
});

const handleGetUsersError = (state) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: false,
  getUsersError: true,
});


const handleGetMoreUsersRequest = (state) => ({
  ...state,
  getMoreUsersRequest: true,
  getMoreUsersSuccess: false,
  getMoreUsersError: false,
});

const handleGetMoreUsersSuccess = (state, action) => ({
  ...state,
  getMoreUsersRequest: false,
  getMoreUsersSuccess: true,
  getMoreUsersError: false,
  usersList: [...state.usersList, ...action.users],
  offset: action.offset,
  isNoMoreUsers: !action.users.length,
});

const handleGetMoreUsersError = (state) => ({
  ...state,
  getMoreUsersRequest: false,
  getMoreUsersSuccess: false,
  getMoreUsersError: true,
});

const handleChangeOffset = (state, action) => ({
  ...state,
  offset: action.offset,
});

const handleUpdateUsersList = (state, action) => ({
  ...state,
  usersList: action.usersList,
});

const handleUpdateUserRequest = (state) => ({
  ...state,
  updateUserRequest: true,
  updateUserSuccess: false,
  updateUserError: false,
});

const handleUpdateUserSuccess = (state, action) => ({
  ...state,
  updateUserRequest: false,
  updateUserSuccess: true,
  updateUserError: false,
});

const handleUpdateUserError = (state) => ({
  ...state,
  updateUsersRequest: false,
  updateUsersSuccess: false,
  updateUsersError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE]: handleResetState,
    [actionTypes.CHANGE_OFFSET]: handleChangeOffset,
    [actionTypes.UPDATE_USERS_LIST]: handleUpdateUsersList,

    [actionTypes.GET_USER_REQUEST]: handleGetUserRequest,
    [actionTypes.GET_USER_SUCCESS]: handleGetUserSuccess,
    [actionTypes.GET_USER_ERROR]: handleGetUserError,

    [actionTypes.GET_USERS_REQUEST]: handleGetUsersRequest,
    [actionTypes.GET_USERS_SUCCESS]: handleGetUsersSuccess,
    [actionTypes.GET_USERS_ERROR]: handleGetUsersError,

    [actionTypes.GET_MORE_USERS_REQUEST]: handleGetMoreUsersRequest,
    [actionTypes.GET_MORE_USERS_SUCCESS]: handleGetMoreUsersSuccess,
    [actionTypes.GET_MORE_USERS_ERROR]: handleGetMoreUsersError,

    [actionTypes.UPDATE_USER_REQUEST]: handleUpdateUserRequest,
    [actionTypes.UPDATE_USER_SUCCESS]: handleUpdateUserSuccess,
    [actionTypes.UPDATE_USER_ERROR]: handleUpdateUserError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
