import { actionTypes } from './actions';

const initialState = {
  getUsersRequest: false,
  getUsersSuccess: false,
  getUsersError: false,

  users: [],
};


const handleGetUsersRequest = (state) => ({
  ...state,
  getUsersRequest: true,
  getUsersSuccess: false,
  getUsersError: false,
});

const handleGetUsersSuccess = (state, action) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: true,
  getUsersError: false,
  users: action.users,
});

const handleGetUsersError = (state) => ({
  ...state,
  getUsersRequest: false,
  getUsersSuccess: false,
  getUsersError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_ISPRING_USERS]: handleResetState,

    [actionTypes.GET_ISPRING_USERS_REQUEST]: handleGetUsersRequest,
    [actionTypes.GET_ISPRING_USERS_SUCCESS]: handleGetUsersSuccess,
    [actionTypes.GET_ISPRING_USERS_ERROR]: handleGetUsersError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
