interface IProps {
  docs: Array<{
    id: number,
    usersUnitId: number
  }>
}

export default function getCultureDocs(props: IProps) {
  const {
    docs,
  } = props;

  let cultureDocs = docs.filter((doc) => {
    return !doc.usersUnitId;
  });

  // We need to sort culture docs by _score.
  let cultureDocsSorted = [];

  // Iterate over already sorted docs.
  docs.forEach((doc) => {
    // If it is culture doc.
    let cultureDoc = cultureDocs.find((cD) => {
      return cD.id === doc.id;
    });

    // Push it to new sorted array;
    if (cultureDoc) {
      cultureDocsSorted.push(cultureDoc);
    }
  });

  cultureDocs = cultureDocsSorted;

  return cultureDocs;
}
