import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import Router from 'next/router';

import urls from '../../common/urls';

import {
  apiCreateNewsArticle,
  apiUpdateNewsArticle,
  apiGetNewsArticle,
  apiDeleteNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsArticleRequest,
  getNewsArticleSuccess,
  getNewsArticleError,
  saveNewsArticleRequest,
  saveNewsArticleSuccess,
  saveNewsArticleError,
  saveAndPublishNewsArticleRequest,
  saveAndPublishNewsArticleSuccess,
  saveAndPublishNewsArticleError,
  unpublishNewsArticleSuccess,
  deleteNewsArticleRequest,
  deleteNewsArticleSuccess,
} from './actions';


function* createNewsArticleSaga(action) {
  try {
    console.log('createNewsArticleSaga');
    console.log('action.params', action.params);

    const data = action.params;
    const isPublishedChange = data.isPublished === true || data.isPublished === false;

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleRequest());
    } else {
      yield put(saveNewsArticleRequest());
    }

    const { data: { data: newsArticle } } = yield call(() => apiCreateNewsArticle(data));

    console.log('created newsArticle', newsArticle);

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleSuccess({ isPublished: data.isPublished }));
    } else {
      yield put(saveNewsArticleSuccess());
    }

    store.addNotification({
      type: 'success',
      message: 'Новость сохранена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });

    Router.push(urls.news.item(), urls.news.item(newsArticle.id));
  } catch (error) {
    console.log('CREATE NEWS ARTICLE ERROR', error);

    yield put(saveAndPublishNewsArticleError());
    yield put(saveNewsArticleError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не сохранена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* updateNewsArticleSaga(action) {
  try {
    console.log('updateNewsArticleSaga');
    console.log('action.params', action.params);

    const data = action.params;
    const isPublishedChange = data.isPublished === true || data.isPublished === false;

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleRequest());
    } else {
      yield put(saveNewsArticleRequest());
    }

    const { data: { data: newsArticle } } = yield call(() => apiUpdateNewsArticle(data));

    const { data: { data: updatedNewsArticle } } = yield call(() => apiGetNewsArticle(data.id));

    console.log('updated newsArticle', updatedNewsArticle);

    console.log('action.callback', action.callback);
    if (action.callback) {
      action.callback(updatedNewsArticle);  
    }

    if (isPublishedChange) {
      yield put(saveAndPublishNewsArticleSuccess(updatedNewsArticle));
    } else {
      yield put(saveNewsArticleSuccess(updatedNewsArticle));
    }

    store.addNotification({
      type: 'success',
      message: 'Новость сохранена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('UPDATE NEWS ARTICLE ERROR', error);

    yield put(saveAndPublishNewsArticleError());
    yield put(saveNewsArticleError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не сохранена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* unpublishNewsArticleSaga(action) {
  try {
    console.log('unpublishNewsArticleSaga');
    console.log('action.newsArticleId', action.newsArticleId);

    yield call(() => apiUpdateNewsArticle({
      id: action.newsArticleId,
      isPublished: false
    }));

    yield put(unpublishNewsArticleSuccess());

    store.addNotification({
      type: 'success',
      message: 'Новость снята с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('UNPUBLISH NEWS ARTICLE ERROR', error);

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не снята с публикации',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* getNewsArticleSaga({ id }) {
  try {
    yield put(getNewsArticleRequest());

    const { data: { data: newsArticle } } = yield call(() => apiGetNewsArticle(id));

    yield put(getNewsArticleSuccess(newsArticle));
  } catch (error) {
    console.log('GET NEWS ARTICLE ERROR', error);
    yield put(getNewsArticleError(error));
  }
}

function* deleteNewsArticleSaga({ newsId }) {
  console.log('deleteNewsArticleSaga', newsId);

  try {
    yield put(deleteNewsArticleRequest());

    yield call(() => apiDeleteNewsArticle(newsId));

    yield put(deleteNewsArticleSuccess());

    Router.push(urls.news.list);

    store.addNotification({
      type: 'success',
      message: 'Новость удалена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('deleteNewsArticleSaga ERROR', error);

    yield put(deleteNewsArticleSuccess());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Новость не удалена',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.CREATE_NEWS_ARTICLE, createNewsArticleSaga);
  yield takeEvery(actionTypes.UPDATE_NEWS_ARTICLE, updateNewsArticleSaga);
  yield takeEvery(actionTypes.GET_NEWS_ARTICLE, getNewsArticleSaga);
  yield takeEvery(actionTypes.UNPUBLISH_NEWS_ARTICLE, unpublishNewsArticleSaga);
  yield takeEvery(actionTypes.DELETE_NEWS_ARTICLE, deleteNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
