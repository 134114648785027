export const actionTypes = {
  GET_USERS_UNITS: 'GET_USERS_UNITS',
  GET_USERS_UNITS_REQUEST: 'GET_USERS_UNITS_REQUEST',
  GET_USERS_UNITS_SUCCESS: 'GET_USERS_UNITS_SUCCESS',
  GET_USERS_UNITS_ERROR: 'GET_USERS_UNITS_ERROR',

  GET_USERS_UNITS_USED: 'GET_USERS_UNITS_USED',
  GET_USERS_UNITS_USED_REQUEST: 'GET_USERS_UNITS_USED_REQUEST',
  GET_USERS_UNITS_USED_SUCCESS: 'GET_USERS_UNITS_USED_SUCCESS',
  GET_USERS_UNITS_USED_ERROR: 'GET_USERS_UNITS_USED_ERROR',

  SET_USERS_UNIT_USED: 'SET_USERS_UNIT_USED',
  UNSET_USERS_UNIT_USED: 'UNSET_USERS_UNIT_USED',

  RESET_STATE_USERS_UNITS: 'RESET_STATE_USERS_UNITS',
  RESET_STATE_USERS_UNITS_USED: 'RESET_STATE_USERS_UNITS_USED',
};

export const resetStateUsersUnits = () => ({ type: actionTypes.RESET_STATE_USERS_UNITS });


export const getUsersUnits = (params) => ({ type: actionTypes.GET_USERS_UNITS, params });

export const getUsersUnitsRequest = () => ({ type: actionTypes.GET_USERS_UNITS_REQUEST });

export const getUsersUnitsSuccess = (data) => ({ type: actionTypes.GET_USERS_UNITS_SUCCESS, data });

export const getUsersUnitsError = (error) => ({ type: actionTypes.GET_USERS_UNITS_ERROR, error });


export const resetStateUsersUnitsUsed = () => ({ type: actionTypes.RESET_STATE_USERS_UNITS_USED });

export const getUsersUnitsUsed = () => ({ type: actionTypes.GET_USERS_UNITS_USED });

export const getUsersUnitsUsedRequest = () => ({ type: actionTypes.GET_USERS_UNITS_USED_REQUEST });

export const getUsersUnitsUsedSuccess = (data) => ({ type: actionTypes.GET_USERS_UNITS_USED_SUCCESS, data });

export const getUsersUnitsUsedError = (error) => ({ type: actionTypes.GET_USERS_UNITS_USED_ERROR, error });


export const setUsersUnitUsed = (data) => ({ type: actionTypes.SET_USERS_UNIT_USED, data });

export const unsetUsersUnitUsed = (data) => ({ type: actionTypes.UNSET_USERS_UNIT_USED, data });
