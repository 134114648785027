import axios from 'axios';

import apiServices from '../../common/apiServices';

export const apiLogin = data => axios({
  method: 'POST',
  url: apiServices.authApi.login,
  data: {
    username: data.email,
    password: data.password,
  },
});

export const apiCheckCurrentUserIsAdmin = data => axios({
  method: 'GET',
  url: apiServices.clientApi.users.checkCurrentUserIsAdmin,
});

export const apiCheckCurrentUserIsBlocked = data => axios({
  method: 'GET',
  url: apiServices.clientApi.users.checkCurrentUserIsBlocked,
});
