export const actionTypes = {
  GET_USERS_AI: 'GET_USERS_AI',
  GET_USERS_AI_REQUEST: 'GET_USERS_AI_REQUEST',
  GET_USERS_AI_SUCCESS: 'GET_USERS_AI_SUCCESS',
};

export const getUsers = (params) => ({ type: actionTypes.GET_USERS_AI, params });

export const getUsersRequest = () => ({ type: actionTypes.GET_USERS_AI_REQUEST });

export const getUsersSuccess = (params) => ({ type: actionTypes.GET_USERS_AI_SUCCESS, ...params });
