import { actionTypes } from './actions';

const initialState = {
  docsFolder: null,
  docsFolderRequest: false,
  docsFolderSuccess: false,
  docsFolderError: false,
  submitDocsFolderRequest: false,
};

const handleGetDocsFolderRequest = (state) => ({
  ...state,
  getDocsFolderRequest: true,
  getDocsFolderSuccess: false,
  getDocsFolderError: false,
});

const handleGetDocsFolderSuccess = (state, action) => ({
  ...state,
  getDocsFolderRequest: false,
  getDocsFolderSuccess: true,
  getDocsFolderError: false,
  docsFolder: action.data,
});

const handleGetDocsFolderError = (state) => ({
  ...state,
  getDocsFolderRequest: false,
  getDocsFolderSuccess: false,
  getDocsFolderError: true,
});

const handleSubmitDocsFolderRequest = (state) => ({
  ...state,
  submitDocsFolderRequest: true,
});

const handleSubmitDocsFolderSuccess = (state, action) => ({
  ...state,
  submitDocsFolderRequest: false,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_FOLDER_EDITOR]: handleResetState,

    [actionTypes.GET_DOCS_FOLDER_REQUEST]: handleGetDocsFolderRequest,
    [actionTypes.GET_DOCS_FOLDER_SUCCESS]: handleGetDocsFolderSuccess,
    [actionTypes.GET_DOCS_FOLDER_ERROR]: handleGetDocsFolderError,

    [actionTypes.SUBMIT_DOCS_FOLDER_REQUEST]: handleSubmitDocsFolderRequest,
    [actionTypes.SUBMIT_DOCS_FOLDER_SUCCESS]: handleSubmitDocsFolderSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
