export const actionTypes = {
  SEARCH_PROJECTS_NEWS: 'SEARCH_PROJECTS_NEWS',
  SEARCH_PROJECTS_NEWS_REQUEST: 'SEARCH_PROJECTS_NEWS_REQUEST',
  SEARCH_PROJECTS_NEWS_SUCCESS: 'SEARCH_PROJECTS_NEWS_SUCCESS',
  SEARCH_PROJECTS_NEWS_ERROR: 'SEARCH_PROJECTS_NEWS_ERROR',

  RESET_STATE_SEARCH_PROJECTS_NEWS: 'RESET_STATE_SEARCH_PROJECTS_NEWS',
};

export const resetStateSearchProjectsNews = () => ({ type: actionTypes.RESET_STATE_SEARCH_PROJECTS_NEWS });

export const searchProjectsNews = (params) => ({ type: actionTypes.SEARCH_PROJECTS_NEWS, ...params });

export const searchProjectsNewsRequest = () => ({ type: actionTypes.SEARCH_PROJECTS_NEWS_REQUEST });

export const searchProjectsNewsSuccess = (data) => ({ type: actionTypes.SEARCH_PROJECTS_NEWS_SUCCESS, data });

export const searchProjectsNewsError = (error) => ({ type: actionTypes.SEARCH_PROJECTS_NEWS_ERROR, error });
