import { actionTypes } from './actions';

const initialState = {
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: false,

  team: {},

  getTeamDocsRequest: false,
  getTeamDocsSuccess: false,
  getTeamDocsError: false,

  docs: [],
};

const handleGetTeamRequest = (state) => ({
  ...state,
  getTeamRequest: true,
  getTeamSuccess: false,
  getTeamError: false,
});

const handleGetTeamSuccess = (state, action) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: true,
  getTeamError: false,
  team: action.team,
});

const handleGetTeamError = (state) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: true,
});

const handleResetStateTeam = (state) => ({
  ...state,
  getTeamRequest: false,
  getTeamSuccess: false,
  getTeamError: false,
  team: {},
});


const handleGetTeamDocsRequest = (state) => ({
  ...state,
  getTeamDocsRequest: true,
  getTeamDocsSuccess: false,
  getTeamDocsError: false,
});

const handleGetTeamDocsSuccess = (state, action) => ({
  ...state,
  getTeamDocsRequest: false,
  getTeamDocsSuccess: true,
  getTeamDocsError: false,
  docs: action.docs,
});

const handleGetTeamDocsError = (state) => ({
  ...state,
  getTeamDocsRequest: false,
  getTeamDocsSuccess: false,
  getTeamDocsError: true,
});

const handleResetStateTeamDocs = (state) => ({
  ...state,
  getTeamDocsRequest: false,
  getTeamDocsSuccess: false,
  getTeamDocsError: false,
  docs: [],
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_TEAM]: handleResetStateTeam,

    [actionTypes.GET_TEAM_REQUEST]: handleGetTeamRequest,
    [actionTypes.GET_TEAM_SUCCESS]: handleGetTeamSuccess,
    [actionTypes.GET_TEAM_ERROR]: handleGetTeamError,


    [actionTypes.RESET_STATE_TEAM_DOCS]: handleResetStateTeamDocs,

    [actionTypes.GET_TEAM_DOCS_REQUEST]: handleGetTeamDocsRequest,
    [actionTypes.GET_TEAM_DOCS_SUCCESS]: handleGetTeamDocsSuccess,
    [actionTypes.GET_TEAM_DOCS_ERROR]: handleGetTeamDocsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
