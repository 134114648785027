import { actionTypes } from './actions';

const initialState = {
  isOpened: false,
};

const handleOpen = (state, action) => ({
  ...state,
  isOpened: true,
});

const handleClose = (state, action) => ({
  ...state,
  isOpened: false,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.OPEN_ASK_CHANGELOG_MODAL]: handleOpen,
    [actionTypes.CLOSE_ASK_CHANGELOG_MODAL]: handleClose,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
