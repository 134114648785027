import { utcToZonedTime, format } from 'date-fns-tz';
import axios from 'axios';

import apiUrls from '../../../common/apiServices';

import checkUpdated from './checkUpdated';

const recordChangeHistory = (props) => {
  const {
    mode, 
    articleData, 
    updatedDoc, 
    oldDocBlocks, 
    removingBlocks,
  } = props;

  const data = [];

  const dateUpdated = new Date();
  const timeZone = 'Europe/Moscow';
  const zonedDate = utcToZonedTime(dateUpdated, timeZone);
  const actualDate = format(zonedDate, 'y-LL-dd kk:mm:ss');

  if (mode === 'edit') {
    removingBlocks.forEach((id) => {
      data.push({
        date: actualDate,
        elemId: id,
        docId: updatedDoc.id,
        type: 'Блок',
        action: 'Удален',
        docTitle: updatedDoc.title,
      });
    });

    console.log('articleData', articleData);
    console.log('updatedDoc', updatedDoc);
    console.log('oldDocBlocks', oldDocBlocks);

    if (articleData.blocks) {
      // iterate old blocks
      oldDocBlocks.forEach((oldDocBlock, i) => {
        // find old block in latest article data
        const articleBlock = articleData.blocks.find(
          (b) => b.id === oldDocBlock.id,
        );

        const obj = {
          date: actualDate,
          elemId: oldDocBlock.id,
          docId: updatedDoc.id,
          type: 'Блок',
          docTitle: updatedDoc.title,
        };
  
        if (articleBlock) {
          if (checkUpdated(oldDocBlock, articleBlock)) {
            data.push({ ...obj, action: 'Обновлен' });
          }
        } else {
          data.push({ ...obj, action: 'Создан' });
        }
      });
    }

    data.push({
      date: actualDate,
      elemId: updatedDoc.id,
      docId: updatedDoc.id,
      type: 'Документ',
      action: 'Обновлен',
      docTitle: updatedDoc.title,
    });
  } else if (mode === 'create') {
    updatedDoc.blocks?.forEach((block) => {
      data.push({
        date: actualDate,
        elemId: block.id,
        docId: updatedDoc.id,
        type: 'Блок',
        action: 'Создан',
        docTitle: updatedDoc.title,
      });
    });

    data.push({
      date: actualDate,
      elemId: updatedDoc.id,
      docId: updatedDoc.id,
      type: 'Документ',
      action: 'Создан',
      docTitle: updatedDoc.title,
    });
  } else {
    updatedDoc.blocks?.forEach((block) => {
      data.push({
        date: actualDate,
        elemId: block.id,
        docId: updatedDoc.id,
        type: 'Блок',
        action: 'Удален',
        docTitle: updatedDoc.title,
      });
    });

    data.push({
      date: actualDate,
      elemId: updatedDoc.id,
      docId: updatedDoc.id,
      type: 'Документ',
      action: 'Удален',
      docTitle: updatedDoc.title,
    });
  }

  axios({
    method: 'POST',
    url: apiUrls.clientApi.docsHistoryChanges.post,
    data,
  })
    .then((res) => console.log(res.data.data))
    .catch((err) => console.log(err));
};

export default recordChangeHistory;
