import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetDocsCommonProcess = (id) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docsCommonProcesses.item(id),
});

export const apiCreateDocsCommonProcess = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.docsCommonProcesses.create,
  data,
});

export const apiUpdateDoc = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.update,
  data,
});

export const apiDetachDocsCommonProcess = (docsCommonProcessId) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.detachDocsCommonProcess,
  params: {
    docsCommonProcessId
  },
});

export const apiUpdateDocsCommonProcess = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docsCommonProcesses.update,
  data,
});

export const apiDeleteDocsCommonProcess = (id) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.docsCommonProcesses.delete,
  params: {
    id,
  },
});
