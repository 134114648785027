import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Typography, styleHelpers } from '@pik/pik-ui';
import S from './styles';

const NavLink = React.forwardRef((props, ref) => {
  const {
    children, isActive, screenType, href, ...rest
  } = props;

  return (
    <S.Container
      ref={ref}
      isActive={isActive}
      href={href}
      {...rest}
    >
      <S.Text>
        {children}
      </S.Text>
    </S.Container>
  );
});


const Link = ({ href, children, screenType, onClickCallback }) => {
  const router = useRouter();
  const isActive = router.asPath === href || (router.asPath.indexOf(href) > -1 && href != '/');

  return (
    <NextLink href={href} passHref>
      <NavLink
        isActive={isActive}
        href={href}
        screenType={screenType}
        onClick={() => {
          onClickCallback();
        }}
      >
        {children}
      </NavLink>
    </NextLink>
  );
};


export default Link;
