import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetUsersUnitsTypes,
} from './api';

import {
  actionTypes,

  getUsersUnitsTypesRequest,
  getUsersUnitsTypesSuccess,
  getUsersUnitsTypesError,
} from './actions';


function* getUsersUnitsTypesSaga(data) {
  try {
    yield put(getUsersUnitsTypesRequest());

    const { data: { data: usersUnits } } = yield call(() => apiGetUsersUnitsTypes());

    yield put(getUsersUnitsTypesSuccess(usersUnits));
  } catch (error) {
    console.log('GET USERS_UNITS TYPES ERROR', error);
    yield put(getUsersUnitsTypesError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_USERS_UNITS_TYPES, getUsersUnitsTypesSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
