import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetTeam,
  apiGetDocsFolder,
  apiGetTeamFolderDocs,
} from './api';

import {
  actionTypes,
  getTeamRequest,
  getTeamSuccess,
  getTeamError,
  getDocsFolderRequest,
  getDocsFolderSuccess,
  getDocsFolderError,
  getTeamFolderDocsRequest,
  getTeamFolderDocsSuccess,
  getTeamFolderDocsError,
} from './actions';

function* getTeamSaga(data) {
  try {
    yield put(getTeamRequest());

    const { data: { data: team } } = yield call(() => apiGetTeam({ usersUnitId: data.params.usersUnitId }));

    yield put(getTeamSuccess({
      team,
    }));
  } catch (error) {
    console.log('GET TEAM ERROR', error);
    yield put(getTeamError(error));
  }
}

function* getDocsFolderSaga(data) {
  try {
    yield put(getDocsFolderRequest());

    const { data: { data: docsFolder } } = yield call(() => apiGetDocsFolder({ docsFolderId: data.params.docsFolderId }));

    yield put(getDocsFolderSuccess({
      docsFolder,
    }));
  } catch (error) {
    console.log('GET DOCS FOLDER ERROR', error);
    yield put(getDocsFolderError(error));
  }
}

function* getTeamFolderDocsSaga(data) {
  try {
    yield put(getTeamFolderDocsRequest());

    const { data: { data: docs } } = yield call(() => apiGetTeamFolderDocs({ usersUnitId: data.params.usersUnitId, docsFolderId: data.params.docsFolderId }));

    yield put(getTeamFolderDocsSuccess({
      docs,
    }));
  } catch (error) {
    console.log('GET TEAM FOLDER DOCS ERROR', error);
    yield put(getTeamFolderDocsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_TEAM_2, getTeamSaga);
  yield takeEvery(actionTypes.GET_DOCS_FOLDER_2, getDocsFolderSaga);
  yield takeEvery(actionTypes.GET_TEAM_FOLDER_DOCS, getTeamFolderDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
