import styled from 'styled-components';
import { media } from '@pik/pik-ui';

import { sizes } from '../../../common/styles/const';

const S = {};


S.Container = styled.div`
  padding-top: ${sizes.topNavHeight};
`;

S.Wrapper = styled.div`

`;

S.Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px
`;

export default S;
