export const actionTypes = {
  OPEN_SIDE_NAV: 'OPEN_SIDE_NAV',
  CLOSE_SIDE_NAV: 'CLOSE_SIDE_NAV',
};

export const open = () => ({
  type: actionTypes.OPEN_SIDE_NAV,
});

export const close = () => ({
  type: actionTypes.CLOSE_SIDE_NAV,
});
