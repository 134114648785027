import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetCultureDocs = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.listCulture,
  params,
});

export const apiDeleteDoc = (docId) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.docs.delete,
  params: {
    id: docId,
  },
});
