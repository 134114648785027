import styled from 'styled-components';

import { styleHelpers } from '@pik/pik-ui';

const S = {};

S.Container = styled.nav`
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  width: 290px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;

  transform: translateX(-290px);

  ${({ isOpened }) => isOpened && `
    transform: translateX(0);
  `}
`;

S.Content = styled.div`
  padding-top: 134px;
  padding-left: 46px;
`;

S.Nav = styled.div`
  cursor: pointer;

  a {
    margin-bottom: 28px;
    text-decoration: none;
  }
`;

S.CloseBtn = styled.button`
  position: absolute;
  top: 40px;
  left: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    width: 15px;
    height: 15px;
    fill: #000;
    transition: fill 0.2s ease;
  }

  &:hover, &:active {
    cursor: pointer;
    border-color: ${styleHelpers.colors.primary};

    svg {
      fill: ${styleHelpers.colors.primary};
    }
  }
`;

S.TelegramLink = styled.a`
  position: absolute;
  bottom: 45px;
  left: 45px;
  display: flex;
  color: #000;
  transition: color 0.2s ease;

  &:hover, &:active {
    cursor: pointer;
    color: ${styleHelpers.colors.primary};

    svg {
      fill: ${styleHelpers.colors.primary};
    }
  }
`;

S.TelegramLinkIcon = styled.div`
  position: relative;
  top: 3px;
  margin-right: 12px;
  width: 15px;
  height: 13px;

  svg {
    width: 15px;
    height: 13px;
    fill: #000;
    transition: fill 0.2s ease;
  }
`;

S.TelegramLinkText = styled.div`
  font-size: 15px;
  line-height: 18px;
`;

S.AILoginButton = styled.div`
  width: max-content;
`;

export default S;
