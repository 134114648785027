export const actionTypes = {
  GET_DOCS_FOLDERS: 'GET_DOCS_FOLDERS',
  GET_DOCS_FOLDERS_REQUEST: 'GET_DOCS_FOLDERS_REQUEST',
  GET_DOCS_FOLDERS_SUCCESS: 'GET_DOCS_FOLDERS_SUCCESS',
  GET_DOCS_FOLDERS_ERROR: 'GET_DOCS_FOLDERS_ERROR',

  ADD_DOCS_FOLDER: 'ADD_DOCS_FOLDER',
  REMOVE_DOCS_FOLDER: 'REMOVE_DOCS_FOLDER',

  RESET_STATE_DOCS_FOLDERS: 'RESET_STATE_DOCS_FOLDERS',
};

export const resetStateDocsFolders = () => ({ type: actionTypes.RESET_STATE_DOCS_FOLDERS });

export const getDocsFolders = (params) => ({ type: actionTypes.GET_DOCS_FOLDERS, ...params });

export const getDocsFoldersRequest = () => ({ type: actionTypes.GET_DOCS_FOLDERS_REQUEST });

export const getDocsFoldersSuccess = (data) => ({ type: actionTypes.GET_DOCS_FOLDERS_SUCCESS, data });

export const getDocsFoldersError = (error) => ({ type: actionTypes.GET_DOCS_FOLDERS_ERROR, error });

export const addDocsFolder = (data) => ({ type: actionTypes.ADD_DOCS_FOLDER, data });

export const removeDocsFolder = (data) => ({ type: actionTypes.REMOVE_DOCS_FOLDER, data });
