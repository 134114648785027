export const actionTypes = {
  GET_ISPRING_USER_RESULTS: 'GET_ISPRING_USER_RESULTS',
  GET_ISPRING_USER_RESULTS_REQUEST: 'GET_ISPRING_USER_RESULTS_REQUEST',
  GET_ISPRING_USER_RESULTS_SUCCESS: 'GET_ISPRING_USER_RESULTS_SUCCESS',
  GET_ISPRING_USER_RESULTS_ERROR: 'GET_ISPRING_USER_RESULTS_ERROR',

  RESET_STATE_ISPRING_USER_RESULTS: 'RESET_STATE_ISPRING_USER_RESULTS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_ISPRING_USER_RESULTS });

export const getUserResults = (params) => ({ type: actionTypes.GET_ISPRING_USER_RESULTS, params });

export const getUserResultsRequest = () => ({ type: actionTypes.GET_ISPRING_USER_RESULTS_REQUEST });

export const getUserResultsSuccess = (params) => ({ type: actionTypes.GET_ISPRING_USER_RESULTS_SUCCESS, ...params });

export const getUserResultsError = (error) => ({ type: actionTypes.GET_ISPRING_USER_RESULTS_ERROR, error });
