import { actionTypes } from './actions';

const initialState = {
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: false,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,
  newsList: [],
  limit: 20,
  offset: 0,
  isNoMoreNews: false,

  getNewsUnpublishedRequest: false,
  getNewsUnpublishedSuccess: false,
  getNewsUnpublishedError: false,
  newsListUnpublished: [],

  unpublishNewsArticleRequest: false,
  deleteNewsArticleRequest: false,
};

const handleGetNewsRequest = (state) => ({
  ...state,
  getNewsRequest: true,
  getNewsSuccess: false,
  getNewsError: false,
});

const handleGetNewsSuccess = (state, action) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: true,
  getNewsError: false,
  newsList: action.news,
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetNewsError = (state) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: true,
});


const handleGetMoreNewsRequest = (state) => ({
  ...state,
  getMoreNewsRequest: true,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,
});

const handleGetMoreNewsSuccess = (state, action) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: true,
  getMoreNewsError: false,
  newsList: [...state.newsList, ...action.news],
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetMoreNewsError = (state) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: true,
});

const handleUpdateNewsArticle = (state, action) => ({
  ...state,
  newsList: action.newsList,
});

const handleResetState = () => ({
  ...initialState,
});

const handleChangeOffset = (state, action) => ({
  ...state,
  offset: action.offset,
});

const handleGetNewsUnpublishedRequest = (state) => ({
  ...state,
  getNewsUnpublishedRequest: true,
  getNewsUnpublishedSuccess: false,
  getNewsUnpublishedError: false,
});

const handleGetNewsUnpublishedSuccess = (state, action) => ({
  ...state,
  getNewsUnpublishedRequest: false,
  getNewsUnpublishedSuccess: true,
  getNewsUnpublishedError: false,
  newsListUnpublished: action.news,
});

const handleGetNewsUnpublishedError = (state) => ({
  ...state,
  getNewsUnpublishedRequest: false,
  getNewsUnpublishedSuccess: false,
  getNewsUnpublishedError: true,
});

const handleUnpublishNewsArticleRequest = (state) => ({
  ...state,
  unpublishNewsArticleRequest: true,
});

const handleUnpublishNewsArticleSuccess = (state, action) => {
  const _newsList = [...state.newsList];
  const _newsListUnpublished = [...state.newsListUnpublished];

  const newsArticleIndex = _newsList.findIndex((newsArticle) => {
    return newsArticle.id === action.newsId;
  });

  _newsListUnpublished.unshift(_newsList[newsArticleIndex]);
  _newsList.splice(newsArticleIndex, 1);

  return {
    ...state,
    newsList: _newsList,
    newsListUnpublished: _newsListUnpublished,
    unpublishNewsArticleRequest: false,
  }
};

const handleDeleteNewsArticleRequest = (state) => ({
  ...state,
  deleteNewsArticleRequest: true,
});

const handleDeleteNewsArticleSuccess = (state, action) => {
  const _newsListUnpublished = [...state.newsListUnpublished];

  const newsArticleIndex = _newsListUnpublished.findIndex((newsArticle) => {
    return newsArticle.id === action.newsId;
  });

  _newsListUnpublished.splice(newsArticleIndex, 1);

  return {
    ...state,
    newsListUnpublished: _newsListUnpublished,
    deleteNewsArticleRequest: false,
  }
};

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_PROJECTS_NEWS]: handleResetState,
    [actionTypes.CHANGE_OFFSET_PROJECTS_NEWS]: handleChangeOffset,

    [actionTypes.GET_PROJECTS_NEWS_REQUEST]: handleGetNewsRequest,
    [actionTypes.GET_PROJECTS_NEWS_SUCCESS]: handleGetNewsSuccess,
    [actionTypes.GET_PROJECTS_NEWS_ERROR]: handleGetNewsError,

    [actionTypes.GET_MORE_PROJECTS_NEWS_REQUEST]: handleGetMoreNewsRequest,
    [actionTypes.GET_MORE_PROJECTS_NEWS_SUCCESS]: handleGetMoreNewsSuccess,
    [actionTypes.GET_MORE_PROJECTS_NEWS_ERROR]: handleGetMoreNewsError,

    [actionTypes.GET_PROJECTS_NEWS_UNPUBLISHED_REQUEST]: handleGetNewsUnpublishedRequest,
    [actionTypes.GET_PROJECTS_NEWS_UNPUBLISHED_SUCCESS]: handleGetNewsUnpublishedSuccess,
    [actionTypes.GET_PROJECTS_NEWS_UNPUBLISHED_ERROR]: handleGetNewsUnpublishedError,

    [actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE]: handleUpdateNewsArticle,

    [actionTypes.UNPUBLISH_NEWS_ARTICLE_PROJECTS_NEWS_REQUEST]: handleUnpublishNewsArticleRequest,
    [actionTypes.UNPUBLISH_NEWS_ARTICLE_PROJECTS_NEWS_SUCCESS]: handleUnpublishNewsArticleSuccess,

    [actionTypes.DELETE_NEWS_ARTICLE_PROJECTS_NEWS_REQUEST]: handleDeleteNewsArticleRequest,
    [actionTypes.DELETE_NEWS_ARTICLE_PROJECTS_NEWS_SUCCESS]: handleDeleteNewsArticleSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
