import { actionTypes } from './actions';

const initialState = {
  newsArticle: null,

  getNewsArticleRequest: false,
  getNewsArticleSuccess: false,
  getNewsArticleError: false,

  saveNewsArticleRequest: false,
  saveAndPublishNewsArticleRequest: false,
  deleteNewsArticleRequest: false,
};

const handleGetNewsArticleRequest = (state) => ({
  ...state,
  getNewsArticleRequest: true,
  getNewsArticleSuccess: false,
  getNewsArticleError: false,
});

const handleGetNewsArticleSuccess = (state, action) => ({
  ...state,
  getNewsArticleRequest: false,
  getNewsArticleSuccess: true,
  getNewsArticleError: false,
  newsArticle: action.newsArticle,
});

const handleGetNewsArticleError = (state) => ({
  ...state,
  getNewsArticleRequest: false,
  getNewsArticleSuccess: false,
  getNewsArticleError: true,
});

const handleSaveNewsArticleRequest = (state) => ({
  ...state,
  saveNewsArticleRequest: true,
});

const handleSaveNewsArticleSuccess = (state, action) => ({
  ...state,
  saveNewsArticleRequest: false,
  newsArticle: action.newsArticle
});

const handleSaveNewsArticleError = (state) => ({
  ...state,
  saveNewsArticleRequest: false,
});

const handleSaveAndPublishNewsArticleRequest = (state) => ({
  ...state,
  saveAndPublishNewsArticleRequest: true,
});

const handleSaveAndPublishNewsArticleSuccess = (state, action) => ({
  ...state,
  saveAndPublishNewsArticleRequest: false,
  newsArticle: action.newsArticle
});

const handleSaveAndPublishNewsArticleError = (state) => ({
  ...state,
  saveAndPublishNewsArticleRequest: false,
});

const handleUnpublishNewsArticleSuccess = (state, action) => ({
  ...state,
  newsArticle: {
    ...state.newsArticle,
    isPublished: false
  }
});

const handleDeleteNewsArticleRequest = (state) => ({
  ...state,
  deleteNewsArticleRequest: true,
});

const handleDeleteNewsArticleSuccess = (state) => ({
  ...state,
  deleteNewsArticleRequest: false,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_ARTICLE_EDITOR]: handleResetState,

    [actionTypes.GET_NEWS_ARTICLE_REQUEST]: handleGetNewsArticleRequest,
    [actionTypes.GET_NEWS_ARTICLE_SUCCESS]: handleGetNewsArticleSuccess,
    [actionTypes.GET_NEWS_ARTICLE_ERROR]: handleGetNewsArticleError,

    [actionTypes.SAVE_NEWS_ARTICLE_REQUEST]: handleSaveNewsArticleRequest,
    [actionTypes.SAVE_NEWS_ARTICLE_SUCCESS]: handleSaveNewsArticleSuccess,
    [actionTypes.SAVE_NEWS_ARTICLE_ERROR]: handleSaveNewsArticleError,

    [actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_REQUEST]: handleSaveAndPublishNewsArticleRequest,
    [actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_SUCCESS]: handleSaveAndPublishNewsArticleSuccess,
    [actionTypes.SAVE_AND_PUBLISH_NEWS_ARTICLE_ERROR]: handleSaveAndPublishNewsArticleError,

    [actionTypes.UNPUBLISH_NEWS_ARTICLE_SUCCESS]: handleUnpublishNewsArticleSuccess,

    [actionTypes.DELETE_NEWS_ARTICLE_REQUEST]: handleDeleteNewsArticleRequest,
    [actionTypes.DELETE_NEWS_ARTICLE_SUCCESS]: handleDeleteNewsArticleSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
