import { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  #modal > div,
  #search-modal > div {
    background: rgba(0, 0, 0, 0.2);
  }

  #modal [width="l"] {
    width: 800px;  
  }

  #search-modal [width="l"] {
    width: 955px;
  }

  #modal [width="l"] > div:nth-child(2),
  #search-modal [width="l"] > div:nth-child(2) {
    border-radius: 30px;
  }

  #modal [width="l"] > div:nth-child(2) {
    width: 800px; 
    max-height: 80vh; 
  }

  #search-modal [width="l"] > div:nth-child(2) {
    width: 955px;
    max-height: 95vh;
  }
`;
