import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetTeam,
  apiGetTeamDocs,
} from './api';

import {
  actionTypes,
  getTeamRequest,
  getTeamSuccess,
  getTeamError,
  getTeamDocsRequest,
  getTeamDocsSuccess,
  getTeamDocsError,
} from './actions';

function* getTeamSaga(data) {
  try {
    yield put(getTeamRequest());

    const { data: { data: team } } = yield call(() => apiGetTeam({ usersUnitId: data.params.usersUnitId }));

    yield put(getTeamSuccess({
      team,
    }));
  } catch (error) {
    console.log('GET TEAM ERROR', error);
    yield put(getTeamError(error));
  }
}

function* getTeamDocsSaga(data) {
  try {
    yield put(getTeamDocsRequest());

    const { data: { data: docs } } = yield call(() => apiGetTeamDocs({ usersUnitId: data.params.usersUnitId }));

    yield put(getTeamDocsSuccess({
      docs,
    }));
  } catch (error) {
    console.log('GET TEAM DOCS ERROR', error);
    yield put(getTeamDocsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_TEAM, getTeamSaga);
  yield takeEvery(actionTypes.GET_TEAM_DOCS, getTeamDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
