export const actionTypes = {
  CREATE_DOCS_FOLDER: 'CREATE_DOCS_FOLDER',
  UPDATE_DOCS_FOLDER: 'UPDATE_DOCS_FOLDER',

  SUBMIT_DOCS_FOLDER_REQUEST: 'SUBMIT_DOCS_FOLDER_REQUEST',
  SUBMIT_DOCS_FOLDER_SUCCESS: 'SUBMIT_DOCS_FOLDER_SUCCESS',

  GET_DOCS_FOLDER: 'GET_DOCS_FOLDER',
  GET_DOCS_FOLDER_REQUEST: 'GET_DOCS_FOLDER_REQUEST',
  GET_DOCS_FOLDER_SUCCESS: 'GET_DOCS_FOLDER_SUCCESS',
  GET_DOCS_FOLDER_ERROR: 'GET_DOCS_FOLDER_ERROR',

  DELETE_DOCS_FOLDER: 'DELETE_DOCS_FOLDER',

  RESET_STATE_DOCS_FOLDER_EDITOR: 'RESET_STATE_DOCS_FOLDER_EDITOR',
};


export const resetState = () => ({ type: actionTypes.RESET_STATE_DOCS_FOLDER_EDITOR });

export const createDocsFolder = (params) => ({ type: actionTypes.CREATE_DOCS_FOLDER, params });

export const updateDocsFolder = (params) => ({ type: actionTypes.UPDATE_DOCS_FOLDER, params });

export const submitDocsFolderRequest = () => ({ type: actionTypes.SUBMIT_DOCS_FOLDER_REQUEST });
export const submitDocsFolderSuccess = () => ({ type: actionTypes.SUBMIT_DOCS_FOLDER_SUCCESS });

export const getDocsFolder = (id) => ({ type: actionTypes.GET_DOCS_FOLDER, id });

export const getDocsFolderRequest = () => ({ type: actionTypes.GET_DOCS_FOLDER_REQUEST });
export const getDocsFolderSuccess = (data) => ({ type: actionTypes.GET_DOCS_FOLDER_SUCCESS, data });
export const getDocsFolderError = (error) => ({ type: actionTypes.GET_DOCS_FOLDER_ERROR, error });

export const deleteDocsFolder = (params) => ({ type: actionTypes.DELETE_DOCS_FOLDER, params });
