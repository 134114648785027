import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetLoginUrl,
} from './api';

import {
  actionTypes,
  getLoginUrlRequest,
  getLoginUrlSuccess,
} from './actions';

function* getLoginUrlSaga() {
  const user = yield select((state) => state.userService.user);
  
  try {
    yield put(getLoginUrlRequest());

    const { data: { data: { loginUrl } } } = yield call(() => apiGetLoginUrl({
      userGlobalId: user.globalId
    }));

    yield put(getLoginUrlSuccess({
      loginUrl,
    }));
  } catch (error) {
    console.log('GET AI LOGIN URL ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_LOGIN_URL, getLoginUrlSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
