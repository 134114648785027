export const actionTypes = {
  GET_USERS: 'GET_USERS',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',

  GET_MORE_USERS: 'GET_MORE_USERS',
  GET_MORE_USERS_REQUEST: 'GET_MORE_USERS_REQUEST',
  GET_MORE_USERS_SUCCESS: 'GET_MORE_USERS_SUCCESS',
  GET_MORE_USERS_ERROR: 'GET_MORE_USERS_ERROR',

  GET_USER: 'GET_USER',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',

  SEARCH_USER: 'SEARCH_USER',

  CHANGE_OFFSET: 'CHANGE_OFFSET',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  TOGGLE_USER_BLOCK_REQUEST: 'TOGGLE_USER_BLOCK_REQUEST',
  TOGGLE_USER_BLOCK_SUCCESS: 'TOGGLE_USER_BLOCK_SUCCESS',
  TOGGLE_USER_BLOCK_ERROR: 'TOGGLE_USER_BLOCK_ERROR',

  TOGGLE_USER_ADMIN_REQUEST: 'TOGGLE_USER_ADMIN_REQUEST',
  TOGGLE_USER_ADMIN_SUCCESS: 'TOGGLE_USER_ADMIN_SUCCESS',
  TOGGLE_USER_ADMIN_ERROR: 'TOGGLE_USER_ADMIN_ERROR',

  UPDATE_USERS_LIST: 'UPDATE_USERS_LIST',

  RESET_STATE: 'RESET_STATE',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE });

export const updateUsersList = (params) => ({ type: actionTypes.UPDATE_USERS_LIST, ...params });


export const searchUser = (params) => ({ type: actionTypes.SEARCH_USER, ...params });

export const getUsers = (params) => ({ type: actionTypes.GET_USERS, params });

export const getUsersRequest = () => ({ type: actionTypes.GET_USERS_REQUEST });

export const getUsersSuccess = (params) => ({ type: actionTypes.GET_USERS_SUCCESS, ...params });

export const getUsersError = (error) => ({ type: actionTypes.GET_USERS_ERROR, error });


// пагинация юзеров
export const getMoreUsers = (params) => ({ type: actionTypes.GET_MORE_USERS, ...params });

export const getMoreUsersRequest = () => ({ type: actionTypes.GET_MORE_USERS_REQUEST });

export const getMoreUsersSuccess = (params) => ({
  type: actionTypes.GET_MORE_USERS_SUCCESS, ...params,
});

export const getMoreUsersError = (error) => ({ type: actionTypes.GET_MORE_USERS_ERROR, error });


export const getUser = (id) => ({ type: actionTypes.GET_USER, id });

export const getUserRequest = () => ({ type: actionTypes.GET_USER_REQUEST });

export const getUserSuccess = (data) => ({ type: actionTypes.GET_USER_SUCCESS, data });

export const getUserError = (error) => ({ type: actionTypes.GET_USER_ERROR, error });

export const toggleUserBlockRequest = () => ({ type: actionTypes.TOGGLE_USER_BLOCK_REQUEST });
export const toggleUserBlockSuccess = () => ({ type: actionTypes.TOGGLE_USER_BLOCK_SUCCESS });
export const toggleUserBlockError = () => ({ type: actionTypes.TOGGLE_USER_BLOCK_ERROR });

export const toggleUserAdminRequest = () => ({ type: actionTypes.TOGGLE_USER_ADMIN_REQUEST });
export const toggleUserAdminSuccess = () => ({ type: actionTypes.TOGGLE_USER_ADMIN_SUCCESS });
export const toggleUserAdminError = () => ({ type: actionTypes.TOGGLE_USER_ADMIN_ERROR });

export const updateUser = (params) => ({ type: actionTypes.UPDATE_USER, params });
export const updateUserRequest = () => ({ type: actionTypes.UPDATE_USER_REQUEST });
export const updateUserSuccess = (params) => ({ type: actionTypes.UPDATE_USER_SUCCESS, ...params });
export const updateUserError = (error) => ({ type: actionTypes.UPDATE_USER_ERROR, error });
