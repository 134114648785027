import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useOnClickOutside from '../../../common/clickOutside';
import { close as closeSideNav } from '../../../services/sideNav/actions';
import { getUsers } from '../../../services/users/actions';
import urls from '../../../common/urls';

import AILoginButton from '../../common/AILoginButton';

import Link from './components/Link';
import localIcons from './icons';

import S from './styles';

const links = [
  {
    id: 1,
    title: 'Новости',
    href: urls.news.list,
  },
  {
    id: 2,
    title: 'База знаний',
    href: urls.docs.list,
  },
  {
    id: 3,
    title: 'Обучение',
    href: urls.learn.list,
  },
  {
    id: 4,
    title: 'Пользователи',
    href: urls.usersList,
  }
];

const links_admin = [
  {
    id: 1,
    title: 'Новости',
    href: urls.news.list,
  },
  {
    id: 2,
    title: 'База знаний',
    href: urls.docs.list,
  },
  {
    id: 3,
    title: 'Обучение',
    href: urls.learn.list,
  },
  {
    id: 4,
    title: 'Пользователи',
    href: urls.usersList,
  },

  {
    id: 5,
    title: 'Команды ЛК',
    href: urls.teams,
  },

  {
    id: 6,
    title: 'Проверка документов',
    href: urls.verficationDocs,
  },

  {
    id: 7,
    title: 'Полезное',
    href: urls.useful.index,
  },
  {
    id: 8,
    title: 'Искусственный интеллект',
    href: urls.ai.usersList,
  }
];

function SideNav(props) {
  const {
    isOpened,
    closeSideNavAction,

    user,

    getUsersAction,
  } = props;

  const [listLinks, setLinksList] = useState(null);

  useEffect(() => {
    if (user && !user.isAdminLimited) {
      setLinksList(links_admin);
    } else {
      setLinksList(links);
    }
  }, [user]);

  const ref = useRef();

  useOnClickOutside(() => {
    closeSideNavAction();
  }, ref.current);

  useEffect(() => {
    getUsersAction();
  }, [getUsersAction]);

  return (
    <S.Container ref={ref} isOpened={isOpened}>
      <S.Content>
        <S.Nav>
          {listLinks?.map(({ id, href, title }) => (
            <Link
              key={id}
              href={href}
              onClickCallback={() => {
                closeSideNavAction();
              }}
            >
              {title}
            </Link>
          ))}
        </S.Nav>

        <S.AILoginButton>
          <AILoginButton isInSideNav />
        </S.AILoginButton>
      </S.Content>

      <S.TelegramLink 
        href={process.env.TG_LIVE_CHANNEL_URL} 
        target="_blank"
      >
        <S.TelegramLinkIcon>{localIcons.telegram}</S.TelegramLinkIcon>
        <S.TelegramLinkText>
          Читайте нас
          <br />
          в телеграме
        </S.TelegramLinkText>
      </S.TelegramLink>

      <S.CloseBtn
        onClick={() => {
          closeSideNavAction();
        }}
      >
        {localIcons.close}
      </S.CloseBtn>
    </S.Container>
  );
}

SideNav.propTypes = {
  getUsersAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ sideNavService, usersService, userService }) => {
  const { isOpened } = sideNavService;

  const {
    usersList,
    getUsersRequest,
    getUsersSuccess,
    getUsersError,
    limit,
    offset,
    isNoMoreUsers,
    getMoreUsersRequest,
    getMoreUsersSuccess,
    getMoreUsersError,
  } = usersService;

  const { user } = userService;

  return {
    isOpened,

    list: usersList,
    getUsersRequest,
    getUsersSuccess,
    getUsersError,
    limit,
    offset,
    isNoMoreUsers,
    getMoreUsersRequest,
    getMoreUsersSuccess,
    getMoreUsersError,
    user,
  };
};

const mapDispatchToProps = {
  closeSideNavAction: closeSideNav,
  getUsersAction: getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
