export const actionTypes = {
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_ERROR: 'GET_CURRENT_USER_ERROR',
  SET_USER_DATA: 'SET_USER_DATA'
};

export const getCurrentUser = (data) => ({ type: actionTypes.GET_CURRENT_USER, data });

export const getCurrentUserRequest = () => ({ type: actionTypes.GET_CURRENT_USER_REQUEST });

export const getCurrentUserSuccess = (data) => ({ type: actionTypes.GET_CURRENT_USER_SUCCESS, data });

export const getCurrentUserError = () => ({ type: actionTypes.GET_CURRENT_USER_ERROR });

export const setUserData = (data) => ({ type: actionTypes.SET_USER_DATA, data });
