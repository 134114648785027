import { actionTypes } from './actions';

const initialState = {
  getCoursesRequest: false,
  getCoursesSuccess: false,
  getCoursesError: false,

  courses: [],
};


const handleGetCoursesRequest = (state) => ({
  ...state,
  getCoursesRequest: true,
  getCoursesSuccess: false,
  getCoursesError: false,
});

const handleGetCoursesSuccess = (state, action) => ({
  ...state,
  getCoursesRequest: false,
  getCoursesSuccess: true,
  getCoursesError: false,
  courses: action.courses,
});

const handleGetCoursesError = (state) => ({
  ...state,
  getCoursesRequest: false,
  getCoursesSuccess: false,
  getCoursesError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_ISPRING_COURSES]: handleResetState,

    [actionTypes.GET_ISPRING_COURSES_REQUEST]: handleGetCoursesRequest,
    [actionTypes.GET_ISPRING_COURSES_SUCCESS]: handleGetCoursesSuccess,
    [actionTypes.GET_ISPRING_COURSES_ERROR]: handleGetCoursesError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
