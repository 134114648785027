import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiSearchDocs,
} from './api';

import {
  actionTypes,

  searchDocsRequest,
  searchDocsSuccess,
  searchDocsError,
} from './actions';


function* searchDocsSaga(data) {
  try {
    yield put(searchDocsRequest());

    let params = {
      search: data.search,
      searchType: data.searchType,
      allDocs: true,
    };

    if (data.usersUnitId) {
      params.usersUnitId = data.usersUnitId;
    }

    const { data: {
      docs: docs,
      success: success
    } } = yield call(() => apiSearchDocs(params));

    if (success) {
      yield put(searchDocsSuccess({
        searchScope: data.searchScope,
        isFolderSearch: data.isFolderSearch,
        docs
      }));  
    } else {
      throw Error({
        success: false
      })
    }
  } catch (error) {
    console.log('SEARCH DOCS ERROR', error);
    yield put(searchDocsError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.SEARCH_DOCS, searchDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
