export const actionTypes = {
  GET_PROJECTS_NEWS_CATEGORY: 'GET_PROJECTS_NEWS_CATEGORY',
  GET_PROJECTS_NEWS_CATEGORY_REQUEST: 'GET_PROJECTS_NEWS_CATEGORY_REQUEST',
  GET_PROJECTS_NEWS_CATEGORY_SUCCESS: 'GET_PROJECTS_NEWS_CATEGORY_SUCCESS',
  GET_PROJECTS_NEWS_CATEGORY_ERROR: 'GET_PROJECTS_NEWS_CATEGORY_ERROR',

  RESET_STATE_PROJECTS_NEWS_CATEGORY: 'RESET_STATE_PROJECTS_NEWS_CATEGORY',
};

export const resetStateProjectsNewsCategory = () => ({ type: actionTypes.RESET_STATE_PROJECTS_NEWS_CATEGORY });


export const getProjectsNewsCategory = (id) => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORY, id });

export const getProjectsNewsCategoryRequest = () => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORY_REQUEST });

export const getProjectsNewsCategorySuccess = (data) => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORY_SUCCESS, data });

export const getProjectsNewsCategoryError = (error) => ({ type: actionTypes.GET_PROJECTS_NEWS_CATEGORY_ERROR, error });
