import axios from 'axios';
import { parseCookies } from 'nookies';

import urls from './urls';
import apiServices from './apiServices';

const LOGIN_PATH = urls.login;
const LOGOUT_PATH = urls.logout;

const checkAuthorized = (tokenLkdp) => axios({
  url: apiServices.authApi.check,
  headers: {
    // Set cookies directly since because axios instance doesn't have context.
    Cookie: `${process.env.AUTH_TOKEN_LKDP_COOKIE_NAME}=${tokenLkdp};`,
  },
})
  .then((response) => {
    return response.status === 200;
  })
  .catch(() => false);

const checkIsAdmin = (tokenLkdp) => axios({
  url: apiServices.clientApi.users.checkCurrentUserIsAdmin,
  headers: {
    // Set cookies directly since because axios instance doesn't have context.
    Cookie: `${process.env.AUTH_TOKEN_LKDP_COOKIE_NAME}=${tokenLkdp};`,
  },
})
  .then((response) => {
    return !!(response.data && response.data.isAdmin);
  })
  .catch(() => false);

const checkIsBlocked = (tokenLkdp) => axios({
  url: apiServices.clientApi.users.checkCurrentUserIsBlocked,
  headers: {
    // Set cookies directly since because axios instance doesn't have context.
    Cookie: `${process.env.AUTH_TOKEN_LKDP_COOKIE_NAME}=${tokenLkdp};`,
  },
})
  .then((response) => {
    return response.data;
  })
  .catch((err) => {
    return err.response.data;
  });

const redirect = (ctx, path) => {
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    window.location.href = path;
  }
};

const logout = (ctx = {}, callbackPath) => {
  if (callbackPath) {
    redirect(ctx, `${LOGOUT_PATH}?callbackPath=${callbackPath}`);
  } else {
    redirect(ctx, LOGOUT_PATH);
  }
};

const getTokenLkdp = (ctx) => parseCookies(ctx)[process.env.AUTH_TOKEN_LKDP_COOKIE_NAME];

const checkAuth = async (ctx, config, callbackPath) => {
  const tokenLkdp = getTokenLkdp(ctx);
  const { pathname } = ctx;

  // нужно только для дев режима, чтобы не было цикла редиректов
  if (LOGIN_PATH === pathname) {
    return null;
  }

  if (tokenLkdp) {
    const isAuthorizedCheck = await checkAuthorized(tokenLkdp);

    if (!isAuthorizedCheck) {
      logout(ctx, callbackPath);
      return null;
    }

    const isBlockedCheck = await checkIsBlocked(tokenLkdp);
    const isAdminCheck = await checkIsAdmin(tokenLkdp);

    if (!isBlockedCheck || !isBlockedCheck.isFound || isBlockedCheck.isBlocked || !isAdminCheck) {
      logout(ctx, callbackPath);
      return null;
    }

    return isAuthorizedCheck;
  }

  logout(ctx, callbackPath);
  return null;
};

export default {
  getTokenLkdp,
  checkAuth,
  logout,
  checkAuthorized,
  redirect,
  LOGIN_PATH,
};
