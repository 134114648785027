export const actionTypes = {
  OPEN_SEARCH: 'OPEN_SEARCH',
  CLOSE_SEARCH: 'CLOSE_SEARCH',
};

export const open = () => ({
  type: actionTypes.OPEN_SEARCH,
});

export const close = () => ({
  type: actionTypes.CLOSE_SEARCH,
});
