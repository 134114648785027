export const actionTypes = {
  GET_TEAM: 'GET_TEAM',
  GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
  GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
  GET_TEAM_ERROR: 'GET_TEAM_ERROR',

  RESET_STATE_TEAM: 'RESET_STATE_TEAM',

  GET_TEAM_DOCS: 'GET_TEAM_DOCS',
  GET_TEAM_DOCS_REQUEST: 'GET_TEAM_DOCS_REQUEST',
  GET_TEAM_DOCS_SUCCESS: 'GET_TEAM_DOCS_SUCCESS',
  GET_TEAM_DOCS_ERROR: 'GET_TEAM_DOCS_ERROR',

  RESET_STATE_TEAM_DOCS: 'RESET_STATE_TEAM_DOCS',
};

export const resetStateTeam = () => ({ type: actionTypes.RESET_STATE_TEAM });

export const getTeam = (params) => ({ type: actionTypes.GET_TEAM, params });

export const getTeamRequest = () => ({ type: actionTypes.GET_TEAM_REQUEST });

export const getTeamSuccess = (params) => ({ type: actionTypes.GET_TEAM_SUCCESS, ...params });

export const getTeamError = (error) => ({ type: actionTypes.GET_TEAM_ERROR, error });


export const resetStateTeamDocs = () => ({ type: actionTypes.RESET_STATE_TEAM_DOCS });

export const getTeamDocs = (params) => ({ type: actionTypes.GET_TEAM_DOCS, params });

export const getTeamDocsRequest = () => ({ type: actionTypes.GET_TEAM_DOCS_REQUEST });

export const getTeamDocsSuccess = (params) => ({ type: actionTypes.GET_TEAM_DOCS_SUCCESS, ...params });

export const getTeamDocsError = (error) => ({ type: actionTypes.GET_TEAM_DOCS_ERROR, error });
