export const actionTypes = {
  CREATE_PROJECTS_NEWS_ARTICLE: 'CREATE_PROJECTS_NEWS_ARTICLE',
  UPDATE_PROJECTS_NEWS_ARTICLE: 'UPDATE_PROJECTS_NEWS_ARTICLE',

  GET_PROJECTS_NEWS_ARTICLE: 'GET_PROJECTS_NEWS_ARTICLE',
  GET_PROJECTS_NEWS_ARTICLE_REQUEST: 'GET_PROJECTS_NEWS_ARTICLE_REQUEST',
  GET_PROJECTS_NEWS_ARTICLE_SUCCESS: 'GET_PROJECTS_NEWS_ARTICLE_SUCCESS',
  GET_PROJECTS_NEWS_ARTICLE_ERROR: 'GET_PROJECTS_NEWS_ARTICLE_ERROR',

  DELETE_PROJECTS_NEWS_ARTICLE: 'DELETE_PROJECTS_NEWS_ARTICLE',

  SAVE_PROJECTS_NEWS_ARTICLE_REQUEST: 'SAVE_PROJECTS_NEWS_ARTICLE_REQUEST',
  SAVE_PROJECTS_NEWS_ARTICLE_SUCCESS: 'SAVE_PROJECTS_NEWS_ARTICLE_SUCCESS',
  SAVE_PROJECTS_NEWS_ARTICLE_ERROR: 'SAVE_PROJECTS_NEWS_ARTICLE_ERROR',

  SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_REQUEST: 'SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_REQUEST',
  SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_SUCCESS: 'SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_SUCCESS',
  SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_ERROR: 'SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_ERROR',

  DELETE_PROJECTS_NEWS_ARTICLE_REQUEST: 'DELETE_PROJECTS_NEWS_ARTICLE_REQUEST',
  DELETE_PROJECTS_NEWS_ARTICLE_SUCCESS: 'DELETE_PROJECTS_NEWS_ARTICLE_SUCCESS',

  RESET_STATE_PROJECTS_NEWS_ARTICLE_EDITOR: 'RESET_STATE_PROJECTS_NEWS_ARTICLE_EDITOR',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_PROJECTS_NEWS_ARTICLE_EDITOR });

export const createNewsArticle = (params) => ({ type: actionTypes.CREATE_PROJECTS_NEWS_ARTICLE, params });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE, params });

export const getNewsArticle = (id) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE, id });

export const getNewsArticleRequest = () => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_REQUEST });

export const getNewsArticleSuccess = (data) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_SUCCESS, data });

export const getNewsArticleError = (error) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_ERROR, error });

export const deleteNewsArticle = (newsId) => ({ type: actionTypes.DELETE_PROJECTS_NEWS_ARTICLE, newsId });

export const saveNewsArticleRequest = () => ({ type: actionTypes.SAVE_PROJECTS_NEWS_ARTICLE_REQUEST });
export const saveNewsArticleSuccess = () => ({ type: actionTypes.SAVE_PROJECTS_NEWS_ARTICLE_SUCCESS });
export const saveNewsArticleError = () => ({ type: actionTypes.SAVE_PROJECTS_NEWS_ARTICLE_ERROR });

export const saveAndPublishNewsArticleRequest = () => ({ type: actionTypes.SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_REQUEST });
export const saveAndPublishNewsArticleSuccess = (data) => ({ type: actionTypes.SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_SUCCESS, data });
export const saveAndPublishNewsArticleError = () => ({ type: actionTypes.SAVE_AND_PUBLISH_PROJECTS_NEWS_ARTICLE_ERROR });

export const deleteNewsArticleRequest = () => ({ type: actionTypes.DELETE_PROJECTS_NEWS_ARTICLE_REQUEST });
export const deleteNewsArticleSuccess = () => ({ type: actionTypes.DELETE_PROJECTS_NEWS_ARTICLE_SUCCESS });
