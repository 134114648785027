import { actionTypes } from './actions';

const initialState = {
  getCultureDocsRequest: false,
  getCultureDocsSuccess: false,
  getCultureDocsError: false,
  getMoreCultureDocsRequest: false,
  getMoreCultureDocsSuccess: false,
  getMoreCultureDocsError: false,
  cultureDocsList: [],
  limit: 20,
  offset: 0,
  isNoMoreCultureDocs: false,

  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: false,
  cultureDocsListUnpublished: [],

  unpublishDocRequest: false,
};

const handleGetCultureDocsRequest = (state) => ({
  ...state,
  getCultureDocsRequest: true,
  getCultureDocsSuccess: false,
  getCultureDocsError: false,
});

const handleGetCultureDocsSuccess = (state, action) => ({
  ...state,
  getCultureDocsRequest: false,
  getCultureDocsSuccess: true,
  getCultureDocsError: false,
  cultureDocsList: action.cultureDocs,
  offset: action.offset,
  isNoMoreCultureDocs: !action.cultureDocs.length,
});

const handleGetCultureDocsError = (state) => ({
  ...state,
  getCultureDocsRequest: false,
  getCultureDocsSuccess: false,
  getCultureDocsError: true,
});


const handleGetMoreCultureDocsRequest = (state) => ({
  ...state,
  getMoreCultureDocsRequest: true,
  getMoreCultureDocsSuccess: false,
  getMoreCultureDocsError: false,
});

const handleGetMoreCultureDocsSuccess = (state, action) => ({
  ...state,
  getMoreCultureDocsRequest: false,
  getMoreCultureDocsSuccess: true,
  getMoreCultureDocsError: false,
  cultureDocsList: [...state.cultureDocsList, ...action.cultureDocs],
  offset: action.offset,
  isNoMoreCultureDocs: !action.cultureDocs.length,
});

const handleGetMoreCultureDocsError = (state) => ({
  ...state,
  getMoreCultureDocsRequest: false,
  getMoreCultureDocsSuccess: false,
  getMoreCultureDocsError: true,
});

const handleResetState = () => ({
  ...initialState,
});

const handleChangeOffset = (state, action) => ({
  ...state,
  offset: action.offset,
});

const handleGetCultureDocsUnpublishedRequest = (state) => ({
  ...state,
  getCultureDocsUnpublishedRequest: true,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: false,
});

const handleGetCultureDocsUnpublishedSuccess = (state, action) => ({
  ...state,
  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: true,
  getCultureDocsUnpublishedError: false,
  cultureDocsListUnpublished: action.cultureDocs,
});

const handleGetCultureDocsUnpublishedError = (state) => ({
  ...state,
  getCultureDocsUnpublishedRequest: false,
  getCultureDocsUnpublishedSuccess: false,
  getCultureDocsUnpublishedError: true,
});

const handleUnpublishDocRequest = (state) => ({
  ...state,
  unpublishDocRequest: true,
});

const handleUnpublishDocSuccess = (state, action) => {
  const _cultureDocsList = [...state.cultureDocsList];

  const docIndex = _cultureDocsList.findIndex((doc) => {
    return doc.id === action.docId;
  });

  _cultureDocsList.splice(docIndex, 1);

  return {
    ...state,
    cultureDocsList: _cultureDocsList,
    unpublishDocRequest: false,
  }
};

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_CULTURE_DOCS]: handleResetState,
    [actionTypes.CHANGE_OFFSET_CULTURE_DOCS]: handleChangeOffset,

    [actionTypes.GET_CULTURE_DOCS_REQUEST]: handleGetCultureDocsRequest,
    [actionTypes.GET_CULTURE_DOCS_SUCCESS]: handleGetCultureDocsSuccess,
    [actionTypes.GET_CULTURE_DOCS_ERROR]: handleGetCultureDocsError,

    [actionTypes.GET_MORE_CULTURE_DOCS_REQUEST]: handleGetMoreCultureDocsRequest,
    [actionTypes.GET_MORE_CULTURE_DOCS_SUCCESS]: handleGetMoreCultureDocsSuccess,
    [actionTypes.GET_MORE_CULTURE_DOCS_ERROR]: handleGetMoreCultureDocsError,

    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_REQUEST]: handleGetCultureDocsUnpublishedRequest,
    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_SUCCESS]: handleGetCultureDocsUnpublishedSuccess,
    [actionTypes.GET_CULTURE_DOCS_UNPUBLISHED_ERROR]: handleGetCultureDocsUnpublishedError,

    [actionTypes.UNPUBLISH_DOC_CULTURE_DOCS_REQUEST]: handleUnpublishDocRequest,
    [actionTypes.UNPUBLISH_DOC_CULTURE_DOCS_SUCCESS]: handleUnpublishDocSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
