import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import { apiGetUsers, apiGetUser, apiUpdateUser } from './api';

import {
  actionTypes,
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  getMoreUsersRequest,
  getMoreUsersSuccess,
  getMoreUsersError,
  getUserRequest,
  getUserSuccess,
  getUserError,
  updateUser,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  toggleUserBlockRequest,
  toggleUserBlockSuccess,
  toggleUserBlockError,
  toggleUserAdminRequest,
  toggleUserAdminSuccess,
  toggleUserAdminError,
  updateUsersList,
} from './actions';

function* getUsersSaga() {
  try {
    yield put(getUsersRequest());
    const { offset, limit } = yield select(({ usersService }) => usersService);

    const {
      data: { data: users },
    } = yield call(() => apiGetUsers({
      offset,
      limit,
    }));

    yield put(
      getUsersSuccess({
        users,
        offset: offset + limit,
      }),
    );
  } catch (error) {
    console.log('GET USER ERROR', error);
    yield put(getUsersError(error));
  }
}

function* getMoreUsersSaga({ search }) {
  try {
    yield put(getMoreUsersRequest());
    const { offset, limit } = yield select(({ usersService }) => usersService);
    const params = { offset, limit };

    if (search && search.length) {
      params.search = search;
    }

    const {
      data: { data: users },
    } = yield call(() => apiGetUsers(params));

    yield put(
      getMoreUsersSuccess({
        users,
        offset: offset + limit,
        isNoMoreUsers: !users.length,
      }),
    );
  } catch (error) {
    console.log('MORE USERS ERROR', error);
    yield put(getMoreUsersError(error));
  }
}

function* searchUserSaga({ search }) {
  try {
    yield put(getUsersRequest());

    const params = {
      offset: 0,
      limit: 50,
    };

    if (search && search.length) {
      params.search = search;
    }

    const {
      data: { data: users },
    } = yield call(() => apiGetUsers(params));

    yield put(
      getUsersSuccess({
        users,
        offset: 50,
        isNoMoreUsers: !users.length || users.length < 50,
      }),
    );
  } catch (error) {
    console.log('SEARCH ERROR', error);
    yield put(getUsersError(error));
  }
}

function* getSingleUserSaga({ id }) {
  try {
    yield put(getUserRequest());

    const {
      data: { data: user },
    } = yield call(() => apiGetUser(id));

    yield put(getUserSuccess(user));
  } catch (error) {
    console.log('GET SINGLE USER ERROR', error);
    yield put(getUserError(error));
  }
}

function* updateUserSaga(action) {
  try {
    console.log('updateUserSaga');
    console.log('action.params', action.params);

    yield put(updateUserRequest());

    const data = action.params;
    const isSuperAdminChange = data.isSuperAdmin === true || data.isSuperAdmin === false;
    const isAdminChange = data.isAdmin === true || data.isAdmin === false;
    const isAdminLimitedChange = data.isAdminLimited === true || data.isAdminLimited === false;
    const isBlockedChange = data.isBlocked === true || data.isBlocked === false;
    const isUserInfoChange = data.username || data.chatID;
    const isCountViewsChange = data.countViews === true || data.countViews === false;
    const isAccessDownloadChange = data.accessDownload === true || data.accessDownload === false;
    const isAdminsComments = data.adminsComments === true || data.adminsComments === false;
    const isAdminBotNotification = data.isAdminBotNotification === true
      || data.isAdminBotNotification === false;

    const {
      data: { data: user },
    } = yield call(() => apiUpdateUser(data));

    const { usersList } = yield select(({ usersService }) => usersService);

    const editedUserIndex = usersList.findIndex(
      (_user) => _user.globalId === data.globalId,
    );

    const updatedUsersList = [...usersList];

    const updatedUser = {
      ...usersList[editedUserIndex],
    };

    if (isSuperAdminChange) {
      updatedUser.isSuperAdmin = data.isSuperAdmin;
    } else if (isAdminChange) {
      updatedUser.isAdmin = data.isAdmin;
    } else if (isBlockedChange) {
      updatedUser.isBlocked = data.isBlocked;
    } else if (isUserInfoChange) {
      updatedUser.username = data.username;
      updatedUser.chatID = data.chatID;
    } else if (data.accessLevel) {
      updatedUser.accessLevel = data.accessLevel;
    } else if (isCountViewsChange) {
      updatedUser.countViews = data.countViews;
    } else if (isAccessDownloadChange) {
      updatedUser.accessDownload = data.accessDownload;
    } else if (isAdminLimitedChange) {
      updatedUser.isAdminLimited = data.isAdminLimited;
    } else if (isAdminsComments) {
      updatedUser.adminsComments = data.adminsComments;
    } else if (isAdminBotNotification) {
      updatedUser.isAdminBotNotification = data.isAdminBotNotification;
    }

    console.log('updatedUser', updatedUser);

    updatedUsersList[editedUserIndex] = updatedUser;

    console.log(777, data);

    yield put(updateUsersList({ usersList: updatedUsersList }));

    yield put(updateUserSuccess());

    store.addNotification({
      type: 'success',
      message: 'Юзер сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('UPDATE USER ERROR', error);

    yield put(updateUserError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Юзер не сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_USER, getSingleUserSaga);
  yield takeEvery(actionTypes.GET_USERS, getUsersSaga);
  yield takeEvery(actionTypes.SEARCH_USER, searchUserSaga);
  yield takeEvery(actionTypes.GET_MORE_USERS, getMoreUsersSaga);
  yield takeEvery(actionTypes.UPDATE_USER, updateUserSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
